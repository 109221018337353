import { dataService } from '../_services/';

export const userregAction = {
    getUsers,
    insertOneUser,
    updateOneUser,
    deleteOneUser,
    findOneCompany,
    CodeFailgetUsers,
};

function getUsers(orderBydesc,company){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"update.time":-1}};
    }
    else{
        options = {"sort":{"update.time":1}};
    }

    return dispatch => {
        dataService.get("users_web",query,options).then(response =>{
            dispatch(changeUserList(response,orderBydesc,company));
    }).catch((err)=>{
        console.log("Get Users Fail "+err);
    })
    };
}

function CodeFailgetUsers(orderBydesc,company,optype){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"update.time":-1}};
    }
    else{
        options = {"sort":{"update.time":1}};
    }

    return dispatch => {
        dataService.get("users_web",query,options).then(response =>{
            dispatch(CodeUserExist(response,orderBydesc,company,optype));
    }).catch((err)=>{
        console.log("Get Users Fail "+err);
    })
    };
}


function findOneCompany(cnpj,orderBydesc){

    return dispatch => {
        dataService.findreallyone("companies",{cnpj:cnpj},{"projection":{"_id":0,"update":0}}).then(response =>{

            if(response){
                dispatch(getUsers(orderBydesc,response));
            }
            else{
                dispatch(CodeFailgetUsers(orderBydesc,response,1));
            }
    }).catch((err)=>{
        console.log("Get Users Fail "+err);
    })
    };
}

function insertOneUser(payload,orderBydesc,company){

    return dispatch => {

        dataService.findone("users_web",{email:payload.email}).then(response =>{
            
            if(response.length === 0){
                dataService.insertone("users_web",payload).then(response => {

                    if(response){
                        dispatch(getUsers(orderBydesc,company));
                    }
                    else{
                        dispatch(CodeFailgetUsers(orderBydesc,company,3));
                    }
                }).catch((err)=>{
                    console.log("Insert One User Fail "+err);
                })
            }
            else{
                dispatch(CodeFailgetUsers(orderBydesc,company,2));
            }
        }).catch((err)=>{
            console.log("Find One Company Fail "+err);
        })
        };
}

function updateOneUser(id,payload,orderBydesc,company){

    return dispatch => {

    dataService.findone("users_web",{email:payload.email}).then(response =>{
        let idstr = JSON.stringify(id);
        let ridstr = JSON.stringify(response[0]._id);

        if((response.length === 0) ||((idstr === ridstr))){
            dataService.updateone("users_web",id,payload).then(response => {

                if(response){
                    dispatch(getUsers(orderBydesc,company));
                }
                else{
                    dispatch(CodeFailgetUsers(orderBydesc,company,4));
                }
            }).catch((err)=>{
                console.log("Update One User Fail "+err);
            })
        }
        else{
            dispatch(CodeFailgetUsers(orderBydesc,company,2));
        }
    }).catch((err)=>{
        console.log("Find One User Fail "+err);
    })
    };
}

function deleteOneUser(id,orderBydesc,company){

    return dispatch => {

        dataService.deleteone("users_web",id).then(response => {

            if(response){
                dispatch(getUsers(orderBydesc,company));
            }
            else{
                dispatch(CodeFailgetUsers(orderBydesc,company,5));
            }
        }).catch((err)=>{
            console.log("Delete One User Fail "+err);
        })
        
    };
}

export function changeUserList(users,orderBydesc,company){

    return{
        type: "FETECHED_ALL_USERS",
        orderbydesc:orderBydesc,
        users: users,
        company:company,
    }
}

export function CodeUserExist(users,orderBydesc,company,optype){
    return{
        type: "UCODE_EXIST",
        orderbydesc:orderBydesc,
        users: users,
        company:company,
        optype:optype
    }
}