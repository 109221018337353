const initialState = {
    success:true,
    orderbydesc:true,
    companies:null,
    optype:0
}

export function company(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_COMPANIES':
            return {
                //...state,
                success:true,
                orderbydesc:action.orderbydesc,
                companies: action.companies,
                optype: 0
            };
        case 'CCODE_EXIST':
          return {
              //...state,
              success:false,
              orderbydesc:action.orderbydesc,
              companies: action.companies,
              optype: action.optype
          };
        default:
            return state
     }
}