import { dataService } from '../_services/';

export const companyAction = {
    getCompanies,
    insertOneCompany,
    updateOneCompany,
    deleteOneCompany,
    findOneCompany,
    CodeFailgetCompanies,
};

function getCompanies(orderBydesc){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"update.time":-1}};
    }
    else{
        options = {"sort":{"update.time":1}};
    }

    return dispatch => {
        dataService.get("companies",query,options).then(response =>{
            dispatch(changeCompanyList(response,orderBydesc));
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}

function CodeFailgetCompanies(orderBydesc,optype){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"update.time":-1}};
    }
    else{
        options = {"sort":{"update.time":1}};
    }

    return dispatch => {
        dataService.get("companies",query,options).then(response =>{
            dispatch(CodeCompanyExist(response,orderBydesc,optype));
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}


function findOneCompany(cnpj,orderBydesc){

    return dispatch => {
        dataService.findreallyone("companies",cnpj).then(response =>{

            if(!response){
                dispatch(changeCompanyList(response,orderBydesc));
            }
            else{
                dispatch(CodeCompanyExist(response,orderBydesc,1));
            }
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}

function insertOneCompany(payload,orderBydesc){

    return dispatch => {

        dataService.findone("companies",{cnpj:payload.cnpj}).then(response =>{
            
            if(response.length === 0){
                dataService.insertone("companies",payload).then(response => {

                    if(response){
                        dispatch(getCompanies(orderBydesc));
                    }
                    else{
                        dispatch(CodeFailgetCompanies(orderBydesc,3));
                    }

                }).catch((err)=>{
                    console.log("Insert One Company Fail "+err);
                })
            }
            else{
                dispatch(CodeFailgetCompanies(orderBydesc,2));
            }
        }).catch((err)=>{
            console.log("Find One Company Fail "+err);
        })
        };
}

function updateOneCompany(id,payload,orderBydesc){

    return dispatch => {

    dataService.findone("companies",{cnpj:payload.cnpj}).then(response =>{
        let idstr = JSON.stringify(id);
        let ridstr = JSON.stringify(response[0]._id);

        if((response.length === 0) ||((idstr === ridstr))){
            dataService.updateone("companies",id,payload).then(response => {
               
                if(response){
                    dispatch(getCompanies(orderBydesc));
                }
                else{
                    dispatch(CodeFailgetCompanies(orderBydesc,4));
                }

            }).catch((err)=>{
                console.log("Update One Company Fail "+err);
            })
        }
        else{
            dispatch(CodeFailgetCompanies(orderBydesc,2));
        }
    }).catch((err)=>{
        console.log("Find One Company Fail "+err);
    })
    };
}

function deleteOneCompany(id,orderBydesc){

    return dispatch => {

        dataService.deleteone("companies",id).then(response => {
            if(response){
                dispatch(getCompanies(orderBydesc));
            }
            else{
                dispatch(CodeFailgetCompanies(orderBydesc,5));
            }
        }).catch((err)=>{
            console.log("Delete One Company Fail "+err);
        })
        
    };
}

export function changeCompanyList(companies,orderBydesc){
    return{
        type: "FETECHED_ALL_COMPANIES",
        orderbydesc:orderBydesc,
        companies: companies,
    }
}

export function CodeCompanyExist(companies,orderBydesc,optype){
    return{
        type: "CCODE_EXIST",
        orderbydesc:orderBydesc,
        companies: companies,
        optype:optype
    }
}