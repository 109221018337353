import { dataService } from '../_services/';

export const reportAction = {
    hideTable,
    aggregationDate,
};

function aggregationDate(dateI,dateF,cnpj){

    return dispatch => {
        /*dataService.get("collections",query,options).then(response =>{
            dispatch(openTable(response,orderBydesc));*/
        dataService.aggregationbyDate("collections",dateI,dateF,cnpj).then(response =>{
            //alert(JSON.stringify(response));
            dispatch(openTable(response));
    }).catch((err)=>{
        console.log("Get Collections Fail "+err);
    })
    };
}

function hideTable(orderBydesc){
    return dispatch => {
        dispatch(closeTable(orderBydesc));
    };
}


export function changeCollectionsList(collections,orderBydesc){
    return{
        type: "FETECHED_ALL_COLLECTIONS",
        orderbydesc:orderBydesc,
        collections: collections,
    }
}

export function openTable(collections){
    return{
        type: "RTABLE_IS_OPENED",
        isopened : true,
        collections : collections,
    }
}

export function closeTable(){
    return{
        type: "RTABLE_IS_CLOSED",
        isopened : false,
        collections : null,
    }
}