import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@material-ui/icons/Home';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import People from '@material-ui/icons/People';
import Drive from '@material-ui/icons/DriveEta';
import ExploreIcon from '@material-ui/icons/Explore';
import ExitToApp from '@material-ui/icons/ExitToApp';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
//import OpacityIcon from '@material-ui/icons/Opacity';
import BusinessIcon from '@material-ui/icons/Business';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { userActions } from '../_actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { green } from '@material-ui/core/colors';

const useStyles = theme => ({
    root: {
      width: '100%',
      maxWidth: 200,
      backgroundColor: theme.palette.background.paper,
     // marginTop: 400,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  });

  class Navigation extends React.Component {

    constructor(props){
        super(props);

        this.state ={
            openregister:false,
        }
    }

    logout = event =>{
        const { dispatch } = this.props;
        dispatch(userActions.logout());
    }
    

    render(){
        const { classes } = this.props;
        let auth = JSON.parse(localStorage.getItem("auth"));

        return(
            <List
                component="nav"
                //aria-labelledby="nested-list-subheader"
                className={classes.root}>
            <ListItem button>
                <ListItemIcon>
                    <HomeIcon style={{ color: green[500] }}/>
                </ListItemIcon>
                <ListItemText primary="Coletas" onClick={()=>history.push('/coletas')} />
            </ListItem>
            <ListItem button >
                <ListItemIcon>
                    <InsertDriveFileIcon style={{ color: green[500] }}/>
                </ListItemIcon>
                <ListItemText primary="Relatórios" onClick={()=>history.push('/relatorios')} />
            </ListItem>
            {(auth.level === 0 || auth.level === 1 || auth.level === 2)?(
            <ListItem button onClick={() => this.setState({ openregister: !this.state.openregister })}>
                <ListItemIcon>
                    <SaveIcon  style={{ color: green[500] }}/>
                </ListItemIcon>
                <ListItemText primary="Cadastros" />
                    {this.state.openregister ? <ExpandLess /> : <ExpandMore />}
            </ListItem>):null}
            <Collapse in={this.state.openregister} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <People style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Produtor" onClick={()=>history.push('/produtores')}/>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <Drive style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Motorista" onClick={()=>history.push('/motoristas')} />
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <ExploreIcon style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Rota" onClick={()=>history.push('/rotas')}/>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <BusinessIcon style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Empresa" onClick={()=>history.push('/empresas')} />
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <EmojiPeopleIcon style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Usuário" onClick={()=>history.push('/usuarios')} />
                    </ListItem>
                   {/*<ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <OpacityIcon style={{ color: green[500] }}/>
                        </ListItemIcon>
                        <ListItemText primary="Tipo de Leite" />
            </ListItem>*/}
                  
                </List>
            </Collapse>
            <ListItem button>
                <ListItemIcon>
                    <ExitToApp style={{ color: green[500] }}/>
                </ListItemIcon>
                <ListItemText primary="LogOut" onClick={(event)=>{this.logout()}} />
            </ListItem>
          </List>
        );
    }
  }

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
    const { loggingIn } = state.authentication;
    return {
       loggingIn
    };
}

export default connect(mapStateToProps)(withStyles(useStyles)(Navigation));