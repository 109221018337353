const initialState = {
  success:true,
  company:null,
  carrier:null,
  orderbydesc:true,
  drivers:null,
  hasdcnpj:false,
  hasccnpj:false,
  optype:0
}

export function driver(state = initialState, action) {
  switch (action.type) {
      case 'FETECHED_ALL_DRIVERS':
          return {
              ...state,
              success:true,
              company: action.company,
              carrier: action.carrier,
              orderbydesc:action.orderbydesc,
              drivers: action.drivers,
              hasdcnpj:action.hasdcnpj,
              hasccnpj:action.hasccnpj,
              optype: 0
          };
      case 'DCODE_EXIST':
          return {
              ...state,
              success:false,
              company: action.company,
              carrier: action.carrier,
              orderbydesc:action.orderbydesc,
              drivers: action.drivers,
              hasdcnpj:action.hasdcnpj,
              hasccnpj:action.hasccnpj,
              optype: action.optype
          };
      case 'FIND_DCNPJ':
          return {
              ...state,
              success:true,
              company:action.company,
              carrier: action.carrier,
              orderbydesc : action.orderbydesc,
              drivers:action.drivers,
              hasdcnpj:action.hasdcnpj,
              hasccnpj:action.hasccnpj,
              optype: 0
          };
      default:
          return state
   }
}