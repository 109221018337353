
const initialState = {
    success:true,
    company:null,
    carrier:null,
    orderbydesc:true,
    producers:null,
    hasdcnpj:false,
    hasccnpj:false,
    optype: 0
}

export function producer(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PRODUCERS':
            return {
                //...state,
                success:true,
                company: action.company,
                carrier: action.carrier,
                orderbydesc:action.orderbydesc,
                producers: action.producers,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: 0
            };
        case 'PCODE_EXIST':
            return {
                //...state,
                success:false,
                company: action.company,
                carrier: action.carrier,
                orderbydesc:action.orderbydesc,
                producers: action.producers,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: action.optype
            };
        case 'FIND_PCNPJ':
            return {
                //...state,
                success:true,
                company:action.company,
                carrier: action.carrier,
                orderbydesc : action.orderbydesc,
                producers: action.producers,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: 0
            };
        default:
            return state
     }
}