
const initialState = {
    success:true,
    company:"buceta",
    carrier:null,
    orderbydesc:true,
    routes:null,
    hasdcnpj:false,
    hasccnpj:false,
    optype: 0
}

export function route(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ROUTES':
            return {
                //...state,
                success:true,
                company: action.company,
                carrier: action.carrier,
                orderbydesc:action.orderbydesc,
                routes: action.routes,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: 0
            };
        case 'RCODE_EXIST':
            return {
                //...state,
                success:false,
                company: action.company,
                carrier: action.carrier,
                orderbydesc:action.orderbydesc,
                routes: action.routes,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: action.optype
            };
        case 'FIND_RCNPJ':
            return {
                //...state,
                success:true,
                company:action.company,
                carrier: action.carrier,
                orderbydesc : action.orderbydesc,
                routes:action.routes,
                hasdcnpj:action.hasdcnpj,
                hasccnpj:action.hasccnpj,
                optype: 0
            };
        default:
            return state
     }
}
