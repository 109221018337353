import React, { Component ,Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Appdrawer from '../_components/appdrawer'
import Typography from '@material-ui/core/Typography';
import { driverAction} from '../_actions';
import { producerAction} from '../_actions';
import { routeAction} from '../_actions';
import { reportAction} from '../_actions';
import { companyAction} from '../_actions';
import Paper from '@material-ui/core/Paper';
import brLocale from "date-fns/locale/pt";
import DateFnsUtils from "@date-io/date-fns/";
import {MuiPickersUtilsProvider,KeyboardDateTimePicker} from "@material-ui/pickers";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import {dateConverter } from '../libs/dateconverter';
import {RegisterTable} from '../_components/registertable';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
//import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import TooltipM from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
/*import {
  LineChart, Line, XAxis, YAxis, CartesianGrid,Tooltip, Legend,
} from 'recharts';*/

const styles = theme => ({
     root: {
        flexGrow: 1,
     },
});

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(60),
        height: theme.spacing(72),
        marginLeft:'auto',
        marginRight:'auto',
      },
    },
    picker: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 220,
      marginTop: theme.spacing(5),
    },
    title: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      color: green[500],
    },
    button: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      width: 460,
    },
    textfield: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 410,
      marginTop: theme.spacing(2),
    },
    companytextfield: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 452,
      marginTop: theme.spacing(2),
    },
    checkbox:{
      marginTop: theme.spacing(5),
    },
    table: {
      minWidth: 450,
    },
    tablecell:{
      fontSize: '8pt',
      paddingRight: 4,
      paddingLeft: 5
    }
  
  }));

function ExportCompartiments(col){

    let str = ""

    if(col.tc.cp1 !== 0){
      str += "CP1:"+col.tc.cp1+" ";
    }
    if(col.tc.cp2 !== 0){
      str += "CP2:"+col.tc.cp2+" ";
    }
    if(col.tc.cp3 !== 0){
      str += "CP3:"+col.tc.cp3+" ";
    }
    if(col.tc.cp4 !== 0){
      str += "CP4:"+col.tc.cp4+" ";
    }
    if(col.tc.cp5 !== 0){
      str += "CP5:"+col.tc.cp5+" ";
    }
    if(col.tc.nc === true){
      str = "Não Coletado"
    }

    return str;
}

function ExportSensors(col){

  let str=""

  if(col.sensors.type1 === 1)
  {
    str += col.sensors.value1+"(°C) ";
  }
  else if(col.sensors.type1 === 2){

    str += col.sensors.value1+"(S) ";
  }

  if(col.sensors.type2 === 1)
  {
    str += col.sensors.value2+"(°C) ";
  }
  else if(col.sensors.type2 === 2){

    str += col.sensors.value2+"(S) ";
  }

  if(col.sensors.type3 === 1)
  {
    str += col.sensors.value3+"(°C) ";
  }
  else if(col.sensors.type3 === 2){

    str += col.sensors.value3+"(S) ";
  }

  if(col.sensors.type4 === 1)
  {
    str += col.sensors.value4+"(°C) ";
  }
  else if(col.sensors.type4 === 2){

    str += col.sensors.value4+"(S) ";
  }

  return str;
}

function subtotal(items) {
  return items.map(({ vol }) => vol).reduce((sum, i) => sum + i, 0);
}

function avg1Ttotal(items) {
  return items.map(({ st1 }) => st1).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg1Ctotal(items) {
  return items.map(({ sc1 }) => sc1).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg2Ttotal(items) {
  return items.map(({ st2 }) => st2).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg2Ctotal(items) {
  return items.map(({ sc2 }) => sc2).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg3Ttotal(items) {
  return items.map(({ st3 }) => st3).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg3Ctotal(items) {
  return items.map(({ sc3 }) => sc3).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg4Ttotal(items) {
  return items.map(({ st4 }) => st4).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}
function avg4Ctotal(items) {
  return items.map(({ sc4 }) => sc4).reduce((prev_avg,i,n) => (prev_avg * n + i) / (n + 1), 0,0);
}

function sensorFormat(num,type) {

  if((type === 1) && (num !== null)){
    return `${num.toFixed(2)}`+"(°C)";
  }
  else if((type === 2)&& (num !== null)){
    return `${num.toFixed(2)}`+"(S)";
  }

  return 0;
}

function NestedCollectionTable(props) {
  const classes = useStyles();

  let rows = props.rows;

return (
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="nested table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {/*<TableCell >Fim</TableCell>*/}
                <TableCell className={classes.tablecell}>Volume(L)</TableCell>
                <TableCell className={classes.tablecell}>Compartimentos</TableCell>
                <TableCell className={classes.tablecell}>Volume Drenado(L)</TableCell>
                <TableCell className={classes.tablecell}>Sensores</TableCell>
                <TableCell className={classes.tablecell}>Motorista</TableCell>
                <TableCell className={classes.tablecell}>Produtor</TableCell>
                <TableCell className={classes.tablecell}>Transportador</TableCell>
                <TableCell className={classes.tablecell}>Laticínio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.doc.map((row) => (
                <TableRow key={row.dataI}>
                   <TableCell colSpan={1} className={classes.tablecell} component="th" scope="row">
                      <p>Início: {dateConverter.UnixtoTime(row.dataI)}</p>
                      <p>Fim: {dateConverter.UnixtoTime(row.dataF)}</p>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                    <p>{row.volume}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.tc.cp1===0?null:"CP1:"+row.tc.cp1}</p>
                    <p>{row.tc.cp2===0?null:"CP2:"+row.tc.cp2}</p>
                    <p>{row.tc.cp3===0?null:"CP3:"+row.tc.cp3}</p>
                    <p>{row.tc.cp4===0?null:"CP4:"+row.tc.cp4}</p>
                    <p>{row.tc.cp5===0?null:"CP5:"+row.tc.cp4}</p>
                    <p>{row.tc.nc===false?null:"Não Coletado"}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.drain.volume}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.sensors.type1===0?null:row.sensors.type1===1?row.sensors.value1+"(°C)":row.sensors.value1+"(S)"}</p>
                    <p>{row.sensors.type2===0?null:row.sensors.type2===1?row.sensors.value2+"(°C)":row.sensors.value2+"(S)"}</p>
                    <p>{row.sensors.type3===0?null:row.sensors.type3===1?row.sensors.value3+"(°C)":row.sensors.value3+"(S)"}</p>
                    <p>{row.sensors.type4===0?null:row.sensors.type4===1?row.sensors.value4+"(°C)":row.sensors.value4+"(S)"}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.driver.code+"-"+row.driver.name}</p>
                    <p>{row.plate}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.producer.code+"-"+row.producer.name}</p>
                    <p>{row.producer.lat+","+row.producer.long}</p>
                    <p>{row.producer.route}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.carrier.name}</p>
                    <p>{row.carrier.cnpj}</p>
                 </TableCell>
                 <TableCell className={classes.tablecell}>
                    <p>{row.company.name}</p>
                    <p>{row.company.cnpj}</p>
                 </TableCell>
                </TableRow>
              ))}
                <TableRow>
                  <TableCell className={classes.tablecell} colSpan={2}>Volume Total:</TableCell>
                  <TableCell className={classes.tablecell} align="right">{rows.vol+"(L)"}</TableCell>
              </TableRow>
              {rows.st1 || rows.sc1?<TableRow>
              <TableCell className={classes.tablecell} colSpan={2}>Média Sensor1:</TableCell>
                  {rows.st1?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.st1,1)}</TableCell>:null}
                  {rows.sc1?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.sc1,2)}</TableCell>:null}
              </TableRow>:null}
              {rows.st2 || rows.sc2?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={2}>Média Sensor2:</TableCell>
                  {rows.st2?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.st2,1)}</TableCell>:null}
                  {rows.sc2?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.sc2,2)}</TableCell>:null}
              </TableRow>:null}
              {rows.st3 || rows.sc3?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={2}>Média Sensor3:</TableCell>
                  {rows.st3?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.st3,1)}</TableCell>:null}
                  {rows.sc3?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.sc3,2)}</TableCell>:null}
              </TableRow>:null}
              {rows.st4 || rows.sc4?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={2}>Média Sensor4:</TableCell>
                  {rows.st4?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.st4,1)}</TableCell>:null}
                  {rows.sc4?<TableCell className={classes.tablecell} align="right">{sensorFormat(rows.sc4,2)}</TableCell>:null}
              </TableRow>:null}
            </TableBody>
          </Table>
        </TableContainer>
      );
}


class Report extends Component {

    constructor(props){
        super(props);
        
        this.state = {
           iscmdopen : true,
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(driverAction.getDrivers(true,null,null,false,false));
        dispatch(producerAction.getProducers(true,null,null,false,false));
        dispatch(routeAction.getRoutes(true,null,null,false,false));
        dispatch(companyAction.getCompanies(true));
    }

     render() {
        const {classes,dispatch,auth} = this.props;
        const {routes} = this.props.routes;
        const {producers} = this.props.producers;
        const {drivers} = this.props.drivers;
        const {companies} = this.props.companies;
        const {success,collections,isopened,orderbydesc} = this.props.collections;


        function CMDForm() {
            const classes = useStyles();
          
            let hs = new Date();
            hs.setHours(0);
            hs.setMinutes(0);
            hs.setSeconds(0);
          
            let hf = new Date();
            hf.setHours(23);
            hf.setMinutes(59);
            hf.setSeconds(59);
          
            const [selectedDateS, handleDateChangeS] = useState(hs);
            const [selectedDateF, handleDateChangeF] = useState(hf);
            const [lroute, setLRoute] = useState(false);
            const [route, setRoute] = useState(routes?(routes.length !== 0)?routes[0].code:null:null);
            const [ldriver, setLDriver] = useState(false);
            const [driver, setDriver] = useState(drivers?(drivers.length !== 0)?drivers[0].code:null:null);
            const [lproducer, setLProducer] = useState(false);
            const [producer, setProducer] = useState(producers?(producers.length !== 0)?producers[0].code:null:null);
            const [lcompany, setLCompany] = useState(false);
            const [company, setCompany ]= useState(companies?(companies.length !== 0)?companies[0].cnpj:null:null);
          

            const handleLRouteChange = (event) => {
                setLRoute(event.target.checked);
              };
            
            const handleLDriverChange = (event) => {
                setLDriver(event.target.checked);
              };

            const handleLProducerChange = (event) => {
                setLProducer(event.target.checked);
              };

            const handleLCompanyChange = (event) => {
                setLCompany(event.target.checked);
              };


            const button = () =>{
                let dateS = dateConverter.DatetoUnix(selectedDateS);
                let dateF = dateConverter.DatetoUnix(selectedDateF)

               /* let query = {dataI:{$gte:dateS},dataF:{$lte:dateF}};

                if(lroute){
                  query["producer.route"] = route;
                }

                if(ldriver){
                  query["driver.code"] = driver;
                }

                if(lproducer){
                  query["producer.code"] = producer;
                }*/

                //dispatch(collectionAction.getCollections(query,orderbydesc));


                 dispatch(reportAction.aggregationDate(dateS,dateF,company));
            };

            return (
              <div className={classes.root}>
                  
                  <Paper elevation={3}>
          
                  <Typography className={classes.title} component="h1" variant="h5">
                     Gerar Relatório
                  </Typography>
          
                  <Fragment >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                    <KeyboardDateTimePicker className={classes.picker}
                        variant="inline"
                        ampm={false}
                        label="Data e Hora Inicial"
                        value={selectedDateS}
                        onChange={handleDateChangeS}
                        onError={console.log}
                        views={["hours", "minutes", "seconds"]}
                        //disablePast
                        format="dd/MM/yyyy HH:mm:ss"
                      />
                  </MuiPickersUtilsProvider>
                 </Fragment>
                 <Fragment >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                      <KeyboardDateTimePicker className={classes.picker}
                        variant="inline"
                        ampm={false}
                        label="Data e Hora Final"
                        value={selectedDateF}
                        onChange={handleDateChangeF}
                        onError={console.log}
                        views={["hours", "minutes", "seconds"]}
                        //disablePast
                        format="dd/MM/yyyy HH:mm:ss"
                      />
                  </MuiPickersUtilsProvider>
                 </Fragment>
                 <Fragment>
                 {/*<Checkbox checked={lcompany} onChange={handleLCompanyChange} className={classes.checkbox} color="primary" />*/}
                    <TextField className={classes.companytextfield}
                        id="standard-select-currency-native"
                        disabled = {/*!lcompany*/false}
                        select
                        margin="normal"
                        required
                        fullWidth
                        label="Empresa"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione a Empresa"
                        >
                        {companies?companies.map((option) => (
                            <option key={option.cnpj} value={option.cnpj}> 
                                {option.name}
                            </option>
                        )):null}
                    </TextField>
                   </Fragment>
                 <Fragment>
                 <Checkbox checked={lroute} onChange={handleLRouteChange} className={classes.checkbox} color="primary" />
                    <TextField className={classes.textfield}
                        id="standard-select-currency-native"
                        disabled = {!lroute}
                        select
                        margin="normal"
                        required
                        fullWidth
                        label="Rota"
                        value={route}
                        onChange={(e) => setRoute(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione a Rota"
                        >
                        {routes?routes.map((option) => (
                            <option key={option.code} value={option.code+"-"+option.name}> 
                                {option.code}-{option.name}
                            </option>
                        )):null}
                    </TextField>
                   </Fragment>
                   <Fragment>
                   <Checkbox checked={lproducer} onChange={handleLProducerChange} className={classes.checkbox} color="primary" />
                   <TextField className={classes.textfield}
                        id="standard-select-currency-native"
                        disabled = {!lproducer}
                        select
                        margin="normal"
                        required
                        fullWidth
                        label="Produtor"
                        value={producer}
                        onChange={(e) => setProducer(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione o Produtor"
                        >
                        {producers?producers.map((option) => (
                            <option key={option.code } value= {option.code}> 
                                {option.code}-{option.name}
                            </option>
                        )):null}
                    </TextField>
                   </Fragment>
                   <Fragment>
                   <Checkbox checked={ldriver} onChange={handleLDriverChange} className={classes.checkbox} color="primary" />
                   <TextField className={classes.textfield}
                        id="standard-select-currency-native"
                        disabled = {!ldriver}
                        select
                        margin="normal"
                        required
                        fullWidth
                        label="Motorista"
                        value={driver}
                        onChange={(e) => setDriver(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione o Motorista"
                        >
                        {drivers?drivers.map((option) => (
                            <option key={option.code} value= {option.code}> 
                                {option.code}-{option.name}
                            </option>
                        )):null}
                    </TextField>
                   </Fragment>
                 <Button
                  //fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={()=>button()}
                >
                  Gerar
                </Button>
          
                  </Paper>
              </div>
            );
        }

        function CollectionTableHead(props)
          {
             //const [orderBy,setOrderBy] = React.useState(orderbydesc);
 
             /*const createSortHandler = (event) => {
                let aux = !orderBy;
                setOrderBy(aux);
           
             };*/

             const exportToCSV = (csvData, fileName) => {

              let custs = []
              for (let i = 0; i < csvData.length; i++) {
                custs.push({Início:dateConverter.UnixtoDate(csvData[i].dataI)+" "+dateConverter.UnixtoTime(csvData[i].dataI),
                            Fim:dateConverter.UnixtoDate(csvData[i].dataF)+" "+dateConverter.UnixtoTime(csvData[i].dataF),
                            "Volume (L)":csvData[i].volume,Compartimentos:ExportCompartiments(csvData[i]),
                            "Volume Drenado (L)":csvData[i].drain.volume,Sensores:ExportSensors(csvData[i]),
                             Motorista:csvData[i].driver.code+"-"+csvData[i].driver.name, 
                             Produtor:csvData[i].producer.code+"-"+csvData[i].producer.name+" "+
                             csvData[i].producer.lat+","+csvData[i].producer.long+" "+csvData[i].producer.route,
                            Transportador:csvData[i].carrier.name+" "+csvData[i].carrier.cnpj,
                            Laticínio:csvData[i].company.name+" "+csvData[i].company.cnpj});
              
                  //alert(JSON.stringify(custs))
                if(i+1 === csvData.length){              
                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(custs);
                  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], {type: fileType});
                  FileSaver.saveAs(data, fileName + fileExtension);
                }
            }
          }

             const handleClickOpenCMD = () =>{
              dispatch(reportAction.hideTable(orderbydesc));
              
             };
 
             return(
               <TableHead>
                    <TooltipM title="Abrir Menu Relatorios">
                    <IconButton onClick={() => handleClickOpenCMD()}> 
                      <ViewHeadlineIcon style={{ color: green[500] }}/> 
                    </IconButton>
                  </TooltipM>
                  <TooltipM title="Exportar Excel">
                    <IconButton onClick={(e) => exportToCSV(collections,"Teste")}> 
                      <GetAppIcon style={{ color: green[500] }}/> 
                    </IconButton>
                  </TooltipM>
                  <TableRow>
                    <TableCell colSpan={2} >
                      Data
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      Coletas
                    </TableCell>
                  
                  </TableRow>

            
             </TableHead>
             );
        }

        function CollectionTableBody(props)
        {
          const {rows,loading} = props;
          const classes = useStyles();

          let totalVolume = (rows?subtotal(rows):0);
          let avg1TTotal = (rows?avg1Ttotal(rows):0);
          let avg2TTotal = (rows?avg2Ttotal(rows):0);
          let avg3TTotal = (rows?avg3Ttotal(rows):0);
          let avg4TTotal = (rows?avg4Ttotal(rows):0);
          let avg1CTotal = (rows?avg1Ctotal(rows):0);
          let avg2CTotal = (rows?avg2Ctotal(rows):0);
          let avg3CTotal = (rows?avg3Ctotal(rows):0);
          let avg4CTotal = (rows?avg4Ctotal(rows):0);

          return(
            <TableBody>
            {(!loading && rows)? rows.map((group) => (
            
              <TableRow key={group._id.sd}>

                  <TableCell style={{ verticalAlign: 'top' }} className = {classes.tablecell} colSpan={2}>
                    {group._id.sd}
                 </TableCell>
                 
                 <TableCell  className = {classes.tablecell}>
                 <NestedCollectionTable rows = {group}/>
                 </TableCell>
 
              </TableRow>
              
            )): null}

             <TableRow>
                  <TableCell className={classes.tablecell} colSpan={3}>Volume Total:{" "+totalVolume+"(L)"}</TableCell>
              </TableRow>
              {avg1TTotal !== 0 || avg1CTotal !== 0 ?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={3}>Média Sensor1:{" "+sensorFormat(avg1TTotal,1)+" "+sensorFormat(avg1CTotal,2)}</TableCell>
              </TableRow>:null}
              {avg2TTotal !== 0 || avg2CTotal !== 0 ?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={3}>Média Sensor2:{" "+sensorFormat(avg2TTotal,1)+" "+sensorFormat(avg2CTotal,2)}</TableCell>
              </TableRow>:null}
              {avg3TTotal !== 0 || avg3CTotal !== 0 ?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={3}>Média Sensor3:{" "+sensorFormat(avg3TTotal,1)+" "+sensorFormat(avg3CTotal,2)}</TableCell>
              </TableRow>:null}
              {avg4TTotal !== 0 || avg4CTotal !== 0 ?<TableRow>
                  <TableCell className={classes.tablecell} colSpan={3}>Média Sensor4:{" "+sensorFormat(avg4TTotal,1)+" "+sensorFormat(avg4CTotal,2)}</TableCell>
              </TableRow>:null}

            </TableBody>
          );
       }


         return (
            <div className={classes.root}>
                 <Appdrawer CMDForm={CMDForm} iscmd={!isopened} title ={"Relatórios"}
                       Table={RegisterTable} Rows={collections} istable={isopened} 
                       THead={CollectionTableHead} TBody={CollectionTableBody}
                       success={success}/>
             </div>
         );
     }
}

Report.propTypes = {
    classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        success : state.success,
        producers : state.producer,
        auth : state.authentication.auth,
        routes : state.route,
        drivers : state.driver,
        collections : state.report,
        companies : state.company,
     };
}
const connectedReportPage = withRouter(connect(mapStateToProps, null, null, {
     pure: false
})(withStyles(styles)(Report)));

export { connectedReportPage as Report };