import { dataService } from '../_services/';

export const collectionAction = {
    getCollections,
    hideTable,
};

function getCollections(query,orderBydesc){

    //let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"dataI":-1}};
    }
    else{
        options = {"sort":{"dataF":1}};
    }

    return dispatch => {
        dataService.get("collections",query,options).then(response =>{
            dispatch(openTable(response,orderBydesc));
    }).catch((err)=>{
        console.log("Get Collections Fail "+err);
    })
    };
}

function hideTable(orderBydesc){
    return dispatch => {
        dispatch(closeTable(orderBydesc));
    };
}


export function changeCollectionsList(collections,orderBydesc){
    return{
        type: "FETECHED_ALL_COLLECTIONS",
        orderbydesc:orderBydesc,
        collections: collections,
    }
}

export function openTable(collections,orderBydesc){
    return{
        type: "TABLE_IS_OPENED",
        orderbydesc:orderBydesc,
        isopened : true,
        collections : collections,
    }
}

export function closeTable(orderBydesc){
    return{
        type: "TABLE_IS_CLOSED",
        orderbydesc:orderBydesc,
        isopened : false,
        collections : null,
    }
}
