const initialState = {
    success:true,
    collections:null,
    isopened  : false,
    orderbydesc:true,
}

export function collection(state = initialState, action) {
    switch (action.type) {
        case 'TABLE_IS_OPENED':
            return {
                ...state,
                success:true,
                collections:action.collections,
                isopened:action.isopened,
                orderbydesc:action.orderbydesc,
            };
        case 'TABLE_IS_CLOSED':
            return {
                ...state,
                success:true,
                collections:action.collections,
                isopened:action.isopened,
                orderbydesc:action.orderbydesc,
            };
        default:
            return state
     }
}