const initialState = {
    success:true,
    orderbydesc:true,
    users:null,
    company:null,
    optype: 0
}

export function user(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_USERS':
            return {
                ...state,
                success:true,
                orderbydesc:action.orderbydesc,
                users: action.users,
                company:action.company,
                optype: 0
            };
        case 'UCODE_EXIST':
          return {
              ...state,
              success:false,
              orderbydesc:action.orderbydesc,
              users: action.users,
              company:action.company,
              optype: action.optype
          };
        default:
            return state
     }
}