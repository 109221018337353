import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 170,
    height:70,
  },
  cardMedia2: {
    width: 170,
    height:100,
  },
});

export default function Clients(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} md={15}>
      <CardActionArea component="a">
        <Card className={classes.card}>
         {post.type === 1?<CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />:<CardMedia className={classes.cardMedia2} image={post.image} title={post.imageTitle} />}
          
         <div className={classes.cardDetails}>
            <CardContent>
            {/*<Typography component="h2" variant="h5">
                {post.title}
            </Typography>*/}
              <Typography variant="subtitle2" paragraph>
                {post.description}
              </Typography>
           </CardContent>
        </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

Clients.propTypes = {
  post: PropTypes.object,
};