import React from 'react';
import clsx from 'clsx';
import { lighten,makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Toolbar } from '@material-ui/core';
import SimpleDialog from '../_components/simpledialog.js';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme =>({
    table: {
      minWidth: 150,
      
    },
    tablecell:{
      fontSize: '8pt',
      paddingRight: 4,
      paddingLeft: 5
    },
    progress: { 
      margin: theme.spacing(2),
      size: 40,
      left: -20,
      top: 10,
      marginLeft: '50%',
    },
    tableContainer:{
      elevation:3,
      //minWidth: 150,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    search: { 
      marginLeft: theme.spacing(2) 
    },
    highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
    },
}));

function TableToolBar(props){
  const classes = useStyles();
  const {title,search,onSearchChange,rows,success,emsg} = props;
  return(
    <Toolbar className={clsx(classes.root, {
      [classes.highlight]: !success,
    })}>

      {!success ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {emsg}
        </Typography>
      ):(
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}{rows?" ("+rows.length.toString()+")":" (0)"}
      </Typography>)}
      <TextField
        value={search}
        onChange={onSearchChange}
        className={classes.search}
        id="input-search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            <SearchIcon />
            </InputAdornment>
          )
        }}
        />
       
  </Toolbar>
  );
}

function MaybeLoading({ loading }) {
  const classes = useStyles();
  return loading ? (
    <CircularProgress className={classes.progress} />
  ) : null;
}

export function stableSort(array) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {

    let order = 0;

    if (b[0].code < a[0].code) {
      order = -1;
    }
    if (b[0].code > a[0].code) {
      order = 1;
    }

    if (order !== 0) return order;
    return a[1].code - b[1].code;
  });
  return stabilizedThis.map((el) => el[0]);
}

export function RegisterTable(props){
    const classes = useStyles();
    const rows = props.rows;
    const {THead,TBody,DialogForm,title,success,emsg} = props;
    let loading = true;
    const [open, setOpen] = React.useState(false);
    const [selectedrow, setRow] = React.useState(null);
    const [type, setType] = React.useState(1);
    const [search, setSearch] = React.useState('');


   if(rows !== null){
    loading = false;
   }

  const handleClickOpen = (row,type) => {
    setOpen(true);
    setRow(row);
    setType(type);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const onSearchChange = e =>{
    setSearch(e.target.value);
  };
  
  return (
    <Paper className={classes.paper}>
      <TableToolBar title={title} search={search} onSearchChange={onSearchChange} rows={rows} success={success}
                     emsg={emsg}/>
    <TableContainer className={classes.tableContainer}>
      <Table  className={classes.table}>
       <THead handleClickOpen={handleClickOpen} classn={classes.tablecell} />
       <TBody handleClickOpen={handleClickOpen} rows={rows} loading={loading} search={search} classn={classes.tablecell}/>
      </Table>
      <MaybeLoading loading={loading} />
      <SimpleDialog  open={open} onClose={handleClose} row={selectedrow} type={type} MainForm={DialogForm}/>
    </TableContainer>
    </Paper>
  );
  
}
