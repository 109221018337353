import { dataService } from '../_services/';

export const producerAction = {
    getProducers,
    insertOneProducer,
    updateOneProducer,
    deleteOneProducer,
    CodeFailgetProducers,
    findOneCompany,
};

function getProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }


    return dispatch => {
        dataService.get("producers",query,options).then(response =>{
            
            dispatch(changeProducersList(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj));
    }).catch((err)=>{
        console.log("Get Producers Fail "+err);
    })
    };
}

function CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.get("producers",query,options).then(response =>{
            dispatch(CodeProducerExist(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype));
    }).catch((err)=>{
        console.log("Get Producers Fail "+err);
    })
    };
}

function insertOneProducer(payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.findone("producers",{code:payload.code,carrier:payload.carrier,company:payload.company})
        .then(response =>{
            //console.log(response.length)
    
            if(response.length === 0){
                dataService.insertone("producers",payload).then(response => {

                    if(response){
                        dispatch(getProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                    }
                    else{
                        dispatch(CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,3));
                    }
                }).catch((err)=>{
                    console.log("Insert One Producer Fail "+err);
                })
            }
            else{
                dispatch(CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
            }
        }).catch((err)=>{
            console.log("Find One Producer Fail "+err);
        })
        };
}

function updateOneProducer(id,payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){


    return dispatch => {

    dataService.findone("producers",{code:payload.code,carrier:payload.carrier,company:payload.company})
    .then(response =>{

        let idstr = null;
        let ridstr = null;

        if(response.length !== 0 ){
            idstr = JSON.stringify(id);
            ridstr = JSON.stringify(response[0]._id);
        }

        if((response.length === 0) ||((idstr === ridstr))){
            dataService.updateone("producers",id,payload).then(response => {

                if(response){
                    dispatch(getProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                }
                else{
                    dispatch(CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,4));
                }
            }).catch((err)=>{
                console.log("Update One Producer Fail "+err);
            })
        }
        else{
            dispatch(CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
        }
    }).catch((err)=>{
        console.log("Find One Producer Fail "+err);
    })
    };
}

function deleteOneProducer(id,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.deleteone("producers",id).then(response => {

            if(response){
                dispatch(getProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
            }
            else{
                dispatch(CodeFailgetProducers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,5));
            }
        }).catch((err)=>{
            console.log("Delete One Producer Fail "+err);
        })
        
    };
}

function findOneCompany(cnpj,type,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.findreallyone("companies",{cnpj:cnpj,type:type},{"projection":{"_id":0}}).then(response =>{

           dataService.get("producers",query,options).then(producers =>{

            if((type === 1) && response){
                dispatch(FindPCNPJ(producers,orderBydesc,response,carrier,true,hasccnpj));
            }
            else if((type === 2)&& response){
                dispatch(FindPCNPJ(producers,orderBydesc,company,response,hasdcnpj,true));
            } 
            else if((type === 1)&& !response){
                dispatch(CodeFailgetProducers(orderBydesc,null,carrier,hasdcnpj,hasccnpj,1));
            }
            else if((type === 2)&& !response){
                dispatch(CodeFailgetProducers(orderBydesc,company,null,hasdcnpj,hasccnpj,1));
            }
           
            }).catch((err)=>{
                console.log("Get Producers Fail "+err);
            })
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}


export function changeProducersList(producers,orderBydesc,company,carrier,hasdcnpj,hasccnpj){
    return{
        type: "FETECHED_ALL_PRODUCERS",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        producers: producers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}

export function CodeProducerExist(producers,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){
    return{
        type: "PCODE_EXIST",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        producers: producers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj,
        optype:optype
    }
}

export function FindPCNPJ(producers,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return{
        type: "FIND_PCNPJ",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        producers: producers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}