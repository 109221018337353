import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Appdrawer from '../_components/appdrawer'
import { userregAction } from '../_actions';
import {RegisterTable} from '../_components/registertable'
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Business from '@material-ui/icons/Business';
import { green } from '@material-ui/core/colors';
import { red }  from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../_components/coypright';
import Tooltip from '@material-ui/core/Tooltip';
import {dateConverter} from '../libs/dateconverter';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const styles = theme => ({
    root: {
       flexGrow: 1,
    },
    home: {
       paddingLeft: theme.spacing(45),
       paddingTop : theme.spacing(4),
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tablecell:{
      fontSize: '8pt',
      paddingRight: 4,
      paddingLeft: 5
    }
  }));

  const levels = [
    {
      value: 0,
      label:'NTI',
    },
    {
      value: 1,
      label:'Matriz',
    },
    {
      value: 2,
      label: 'Gerente',
    },
    {
      value: 3,
      label: 'Usuário',
    }
  ];


class User extends Component{

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userregAction.getUsers(true,null));
    }

    errorMSG(optype){

      let alertmsg = "";

        if(optype === 1){
          alertmsg = "Erro,Empresa não Cadastrada.";
        }
        else if(optype === 2){
          alertmsg ="Erro,Já Existe um Usuário Cadastrado com esse Código";
        }
        else if(optype === 3){
          alertmsg ="Erro,Não foi Possível Inserir Usuário";
        }
        else if(optype === 4){
          alertmsg ="Erro,Não foi Possível Modificar Usuário";
        }
        else if(optype === 5){
          alertmsg ="Erro,Não foi Possível Remover Usuário";
        }

        return alertmsg;
    }

    render(){
        const { classes,dispatch,auth} = this.props;
        const {success,users,orderbydesc,company,optype} = this.props.users;

        let alertmsg = this.errorMSG(optype);

        function UserForm(props) {

            const classes = useStyles();
            let label = null;
            let buttonlabel = null;
            const {row,type,handleClose} = props;
            const [cnpj,setCNPJ] = React.useState(type=== 1 && company?company.cnpj:type !== 1 && row?row.company.cnpj:'');
            const [name,setName] = React.useState(row?row.name:'');
            const [email,setEmail] = React.useState(row?row.email:'');
            const [errormail,setErrormail] = React.useState('');
            const [showchild,setChild] = React.useState(true);
            const [errorcnpj,setErrorCNPJ] = React.useState('');
            const [clevel, setCLevel] = React.useState(row?row.level:1);
            const [active, setActive] = React.useState(row?row.active:false);
            const [changepass, setChangePass] = React.useState(false);
            const [password,setPassword] = React.useState(row?row.password:'');
            const [npassword,setNPassword] = React.useState('');
            const [cnpassword,setCNPassword] = React.useState('');
            const [errorpass, setErrorPass] = React.useState('');
            const [errornpass, setErrorNPass] = React.useState('');
            const [errorcnpass, setErrorCNPass] = React.useState('');

            if(type === 1){
                label = "Cadastrar Usuário";
                buttonlabel = "Cadastrar";
              }
              else if(type === 2){
                label = "Modificar Usuário";
                buttonlabel = "Modificar";
              }
              else if(type === 3){
                label = "Remover Usuário";
                buttonlabel = "Remover";
              }
            
            const ChangeCNPJ = (event) =>{

                setCNPJ(event.target.value)
  
                if(event.target.value.match(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/)){
                  setErrorCNPJ('');
                  dispatch(userregAction.findOneCompany(event.target.value,orderbydesc,company));
                }
                else{
                  setErrorCNPJ('Formato Inválido');
                }
              };

            const ChangeCLevel = (event) => {

               if(parseInt(event.target.value) === 0 && parseInt(auth.level) === 2) {
                setCLevel(parseInt(event.target.value) +1);
               }
               else {
                setCLevel(event.target.value);
               } 
              };

            const handleActiveChange = (event) => {
                setActive(event.target.checked);
              };

            const handleChangePass = (event) => {
                setChangePass(event.target.checked);
                setPassword('');
                setErrorPass("Digite a Senha");
            };

            const ChangeEmail = (event) =>{

                setEmail(event.target.value)
  
                if(event.target.value.match(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)){
                  setErrormail('');
                }
                else{
                  setErrormail('Formato Inválido');
                }
              };

            const changePassword = (event) =>{

                setPassword(event.target.value)

                if(event.target.value === row.password){
                    setErrorPass('');
                    setErrorNPass("Digite a Nova Senha")

                }
                else{
                    setErrorPass('Senha não Confere');
                }
            };

            const changeNPassword = (event) =>{
                setNPassword(event.target.value)

                if(event.target.value.length >= 6){
                    setErrorNPass('');
                    setErrorCNPass("Confirme a Nova Senha")

                }
                else{
                    setErrorNPass('A Senha Deve Possuir Pelo Menos 6 Caracteres');
                }
            };

            const changeCNPassword = (event) =>{
                setCNPassword(event.target.value)

                if(event.target.value === npassword){
                    setErrorCNPass('');
                    setNPassword(npassword);

                }
                else{
                    setErrorCNPass('Erro ao Confirmar Senha');
                }
            };

           const button = (operation) =>{
      
                if (name && email) {

                  let access = [];

                  access.push(type=== 1 && company?company.cnpj:type !== 1 && row?row.company.cnpj:'');

                  if((row?row.company.hashq:false)||(company?company.hashq:false)){
                    access.push(type=== 1 && company.hashq?company.hq.cnpj:type !== 1 && row?row.company.hq.cnpj:'')
                  }
                

                  let payload ={email:email,name:name,password:password,access:access,level:parseInt(clevel),active:active,
                                userid:row?row.userid:"",logintime:new Date().getTime(),company:type=== 1 && company?company:type !== 1 && row?row.company:'',
                                update:{user:auth.name,time:new Date().getTime()}}; 
                  
                  switch(operation){
                    case 1:
                      dispatch(userregAction.insertOneUser(payload,orderbydesc,company));
                      break;
                    case 2:
                      setChild(false);
                      
                      dispatch(userregAction.updateOneUser(row._id,payload,orderbydesc,company));
                      handleClose();
                      break;
                    case 3:
                      setChild(false);
                      dispatch(userregAction.deleteOneUser(row._id,orderbydesc,company));
                      handleClose();
                      break;
                    default:
                      break;
  
                  }
                }
            };
          
            return (
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <Avatar className= {classes.avatar}>
                    <Business />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    {label}
                  </Typography>
                  {showchild &&<form className={classes.root} noValidate>

                    <TextField
                      error={errorcnpj===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="cnpj"
                      label="CNPJ/Empresa"
                      name="cnpj"
                      autoComplete="cnpj"
                      value={cnpj}
                      onChange ={ChangeCNPJ}
                      helperText = {errorcnpj}
                      disable = {auth.level===3 ?true:false}
                    />
                    <TextField
                      error={errormail===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange ={ChangeEmail}
                      helperText = {errormail}
                      disable = {auth.level===3 ?true:false}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      autoComplete="name"
                      value={name}
                      onChange ={(e) => setName(e.target.value)}
                      disable = {auth.level===3 ?true:false}
                    />
                     {auth.level === 0 || auth.level === 2 ?<TextField
                        id="standard-select-currency-native"
                        select
                        margin="normal"
                        required
                        fullWidth
                        label="Nível de Acesso"
                        value={clevel}
                        onChange={ChangeCLevel}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione o Nível de Acesso"
                        >
                        {levels.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                    </TextField>:null}
                    {auth.level === 0 && auth.active === true && type === 2?<FormControlLabel
                      control={<Checkbox checked={active} onChange={handleActiveChange} color="primary" />}
                      label="Ativo"
                    />:null}
                    {auth.level === 3 && type === 2?<FormControlLabel
                      control={<Checkbox checked={changepass} onChange={handleChangePass} color="primary" />}
                      label="Modificar Senha"
                    />:null}
                    {changepass && type===2?<TextField
                      error={errorpass===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="senha"
                      label="Senha"
                      name="senha"
                      autoComplete="senha"
                      type="password"
                      value={password}
                      onChange ={changePassword}
                      helperText = {errorpass}
                    />:null}
                    {changepass && type===2?<TextField
                      error={errornpass===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="nsenha"
                      label="Nova Senha"
                      name="nsenha"
                      autoComplete="nsenha"
                      type="password"
                      value={npassword}
                      onChange ={changeNPassword}
                      helperText = {errornpass}
                    />:null}
                     {changepass && type===2?<TextField
                      error={errorcnpass===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="cnsenha"
                      label="Confirma Nova Senha"
                      name="cnsenha"
                      autoComplete="cnsenha"
                      type="password"
                      value={cnpassword}
                      onChange ={changeCNPassword}
                      helperText = {errorcnpass}
                    />:null}
                    <Button
                      //type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={()=>button(type)}
                      disabled = {(errorcnpj===''?false:true) || (errormail===''?false:true) 
                                 || (changepass?errornpass === ''?false:true:false) ||
                                 (changepass?errorpass === ''?false:true:false) ||
                                 (changepass?errorcnpass === ''?false:true:false) || (type === 1 && !company?true:false) }
                    >
                      {buttonlabel}
                    </Button>
                  </form>}
                </div>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Container>
            );
          }

        function UsersTableHead(props)
        {
           const {handleClickOpen} = props;
           const [orderBy,setOrderBy] = React.useState(orderbydesc);
           const classes = useStyles();
           const classn = classes.tablecell;

           const createSortHandler = (event) => {
              let aux = !orderBy;
              setOrderBy(aux);
              dispatch(userregAction.getUsers(aux));
         
           };

           return(
             <TableHead>
             <TableRow>
               <TableCell className={classn}>Email</TableCell>
               <TableCell className={classn}>Nome</TableCell>
               <TableCell className={classn}>Nível de Acesso</TableCell>
               <TableCell className={classn}>Ativo</TableCell>
               <TableCell className={classn}>Último Login</TableCell>
               <TableCell className={classn}>Empresa/CNPJ</TableCell>
               <TableCell className={classn}>Ultima Alteração
               <TableSortLabel
                       active={true}
                       direction={orderBy?'desc':'asc'}
                       onClick={createSortHandler}
                 />
               </TableCell>
               <TableCell > 
               <Tooltip title="Adicionar">
                 <IconButton onClick={() => handleClickOpen(null,1)}> 
                   <AddCircleIcon fontSize="small" style={{ color: green[500] }}/> 
                 </IconButton>
                 </Tooltip>
               </TableCell>
             </TableRow>
           </TableHead>
           );
        }

        function UsersTableBody(props)
        {
          const {rows,handleClickOpen,loading,search} = props;
          const classes = useStyles();
          const classn = classes.tablecell;

          const handleActivateAccount = (row) => {
                    
            const crypto = require('crypto');

            let hash = crypto.createHmac('sha256', JSON.stringify(row._id))
              .update('activationcode')
              .digest('hex');

            hash = hash.slice(58);

            let payload ={email:row.email,name:row.name,password:hash,access:row.access,level:parseInt(row.level),active:true,
              userid:"",logintime:new Date().getTime(),company:row.company,
              update:{user:auth.name,time:new Date().getTime()}}; 

            dispatch(userregAction.updateOneUser(row._id,payload,orderbydesc,company));
          };

          return(
            <TableBody>
            {!loading ? rows
            .filter(row =>!search || row.name.includes(search) || row.email.includes(search) || 
            row.company.cnpj.includes(search))
            .map((row) => (
            
              <TableRow key={row.update.time}>
                <TableCell className={classn} component="th" scope="row">{row.email}</TableCell>
                <TableCell className={classn} >{row.name}</TableCell>
                <TableCell className={classn}>{row.level===0?"NTI":row.level===1?"Matriz":row.level===2?"Gerente":"Usuário"}</TableCell>
                <TableCell >
                      <Checkbox checked={row.active} color="primary" />
                </TableCell>
                <TableCell className={classn}>
                  <p>{dateConverter.UnixtoDate(row.logintime)}</p>
                  <p>{dateConverter.UnixtoTime(row.logintime)}</p>
                </TableCell>
                <TableCell className={classn}>
                     <p>{row.company.name}</p>
                     <p>{row.company.cnpj}</p>
                </TableCell>
                <TableCell className={classn}>
                  <p>Usuário: {row.update.user}</p>
                  <p>{dateConverter.UnixtoDate(row.update.time)}</p>
                  <p>{dateConverter.UnixtoTime(row.update.time)}</p>
                </TableCell>
                <TableCell >
                  {row.active?<Tooltip title="Editar">
                      <IconButton onClick={() => handleClickOpen(row,2)}> 
                        <EditIcon fontSize="small" style={{ color: green[500] }}/> 
                      </IconButton>
                  </Tooltip>:null}
                  {row.active?<Tooltip title="Remover">
                      <IconButton onClick={() => handleClickOpen(row,3)}> 
                        <DeleteIcon fontSize="small" style={{ color: red[300] }}/> 
                      </IconButton>
                  </Tooltip>:null}
                  {auth.level === 0 && !row.active?<Tooltip title="Ativar Conta">
                      <IconButton onClick={() => handleActivateAccount(row)}> 
                        <CheckCircleIcon fontSize="small" style={{ color: green[300] }}/> 
                      </IconButton>
                  </Tooltip>:null}
                </TableCell>
              </TableRow>
              
            )): null}
            </TableBody>
          );
       }

        return(
            <div className={classes.root}>
            <Appdrawer Table={RegisterTable} Rows={users} istable={true} 
             THead={UsersTableHead} TBody={UsersTableBody} DialogForm={UserForm}
             title={"Usuários"} success={success} emsg={alertmsg}/>
            </div>
        );
    }

}

User.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {

    return {
        users : state.user,
        auth : state.authentication.auth
     };
}
const connectedUserPage = withRouter(connect(mapStateToProps, null, null, {
     pure: false
})(withStyles(styles)(User)));

export { connectedUserPage as User };