import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Header from '../_site/header';
import About from '../_site/about';
import Services from '../_site/services';
import Clients from '../_site/clients';
import Contact from '../_site/contact';
import Footer from '../_site/footer';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margins:{
    //marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  divider:{
    marginBottom: theme.spacing(4),
  },
  
}));

const sections = [
  { title: 'Serviços', url: '#servicos' },
  { title: 'Parceiros e Clientes', url: '#' },
  { title: 'Contato', url: '#' },

];

const about = {
  title: 'NTI Automação',
  description:
  "Fundada em 2019,em Foz do Iguaçu,para entrar no programa de incubação do PTI em Itaipu, a NTI é a continuação da antiga G2E Automação e Tecnologia, empresa fundada em 2016 em Curitiba.Como sua antecessora a NTI é voltada para área de automação, realizando projetos customizados para o mercado do agronegócio, energia, retrofit de máquinas, desenvolvimento de softwares e manutenção de equipamentos eletrônicos.",
  image: 'https://source.unsplash.com/featured/?{technology},{automation}',
  //imgText: '',
  //linkText: 'Continue reading…',
};

const services = [
  {
    title: 'Agricultura\n',
    //date: 'Nov 12',
    description:
      'Soluções de Pesagem Embarcada, Medição de Silo entre outras.',
    image: require('../_img/agricultural.jpg'),
    //imageText: 'Image Text',
  },
  {
    title: 'Laticínios',
    //date: 'Nov 11',
    description:
      'Soluções de Automação de Processos para produção de Queijo, Manteiga, Iogurte, Medição de Silo entre outras.',
    image: require('../_img/dairy3.jpg'),
    //imageText: 'Image Text',
  },
  {
    title: 'Transportadores de Leite',
    //date: 'Nov 11',
    description:
      'Dispositivo embarcado no caminhão, o qual tem a função de medir o volume e a temperatura do leite coletado.',
    image: require('../_img/truck.jpeg'),
    //imageText: 'Image Text',
  },
  {
    title: 'Automação Industrial',
    //date: 'Nov 11',
    description:
      'Solução de automação industrial em geral, utilizando dos melhores equipamentos do mercado, montagem de quadro elétrico, programação de clp, entre outras.\n',
    image: require('../_img/industry.jpeg'),
    //imageText: 'Image Text',
  },
];

const clients = [
  {
    title: 'PTI',
    description:
      'Parceiro de Negócios, e desenvolvimento de projetos.',
    image: require('../_img/pti.jpg'),
    type : 1,
    //imageText: 'Image Text',
  },

  {
    title: 'Gvinah',
    //date: 'Nov 11',
    description:
      'Integração de um medidor de vazão Krohne com uma IHM e com o PC da empresa.',
    image: require('../_img/gvinah.png'),
    type : 1,
    //imageText: 'Image Text',
  },
  {
    title: 'Coopar',
    //date: 'Nov 11',
    description:
      'Desenvolvimento de um software de Gerenciamento para recepção de Leite,integrado ao medidor de vazão e com acesso web.',
    image: require('../_img/coopar.png'),
    type : 2,
    //imageText: 'Image Text',
  },
  {
    title: 'ZDA',
    //date: 'Nov 11',
    description:
      'Automação dos Processos da Queijomatic e da Produção de Manteiga no Laticínio Nituano em Marechal Cândido Rondom.',
    image: require('../_img/zda.png'),
    type : 2,
    //imageText: 'Image Text',
  },
  {
    title: 'Fabo Bombas',
    description:
      'Desenvolvimento de um software de Gerenciamento para recepção de Leite, manutenção eletrônica, desenvolvimento de projetos, automação de máquinas.',
    image: require('../_img/fabo.png'),
    type : 1,
    //imageText: 'Image Text',
  },
];



export default function Main() {
  const classes = useStyles();

  return (
    <React.Fragment>
    <CssBaseline />
    <Header title=""  sections={sections}/>
    {/*<Container maxWidth="lg">*/}
    <main>
          <About post={about}/>
          <Container maxWidth="lg">

          <Typography component="h2" variant="h5" className={classes.margins}>
            Serviços
          </Typography>
          <Divider className={classes.divider}/>
          <Grid container spacing={4}>
            {services.map((post) => (
              <Services key={post.title} post={post} />
            ))}
          </Grid>
          <Typography component="h2" variant="h5" className={classes.margins}>
            Alguns Parceiros e Clientes
          </Typography>
          <Divider className={classes.divider}/>
          <Grid container spacing={4}>
            {clients.map((post) => (
              <Clients key={post.title} post={post} />
            ))}
          </Grid>
          <Typography component="h2" variant="h5" className={classes.margins}>
            Contato
          </Typography>
          <Divider className={classes.divider}/>
          <Contact/>
          </Container>
    </main>
    <Footer title="" description="" />
     
   {/* </Container>*/}
   
    </React.Fragment>
  );
}


const connectedMainPage = withRouter(connect(null, null, null, {
    pure: false
})(withStyles(useStyles)(Main)));

export { connectedMainPage as Main};