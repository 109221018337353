import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 100,
    height:100,
  },
});

export default function Contact() {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={15}>
      <CardActionArea component="a">
        <Card className={classes.card}>
        <CardMedia className={classes.cardMedia} image={require('../ntigreen.png')}/>
          <div className={classes.cardDetails}>
            <CardContent>
              <Typography variant="subtitle1">
                Av Tancredo Neves, 6731
              </Typography>
              <Typography variant="subtitle1">
                Sala 108, Andar 1, Edifício das Águas PTI
              </Typography>
              <Typography variant="subtitle1" paragraph>
                CEP: 85867-900, Jardim Itaipu
              </Typography>
              <Typography variant="subtitle1" paragraph>
                Email: ntiautomacao@gmail.com
              </Typography>
              <Typography variant="subtitle1">
                Telefones:
              </Typography>
              <Typography variant="subtitle1">
                (41)99911-0552 João Victor
              </Typography>
              <Typography variant="subtitle1">
                (41)99949-3439 Rafael
              </Typography>
              <Typography variant="subtitle1">
                (45)99961-3256 Aécio
              </Typography>
            </CardContent>
          </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

