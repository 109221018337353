import { dataService } from '../_services/';

export const routeAction = {
    getRoutes,
    insertOneRoute,
    updateOneRoute,
    deleteOneRoute,
    CodeFailgetRoutes,
    findOneCompany,
};

function getRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }


    return dispatch => {
        dataService.get("routes",query,options).then(response =>{
            
            dispatch(changeRoutesList(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj));
    }).catch((err)=>{
        console.log("Get Routes Fail "+err);
    })
    };
}

function CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.get("routes",query,options).then(response =>{
            dispatch(CodeRouteExist(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype));
    }).catch((err)=>{
        console.log("Get Routes Fail "+err);
    })
    };
}

function insertOneRoute(payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.findone("routes",{code:payload.code,carrier:payload.carrier,company:payload.company})
        .then(response =>{
            //console.log(response.length)
    
            if(response.length === 0){
                dataService.insertone("routes",payload).then(response => {

                    if(response){
                        dispatch(getRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                    }
                    else{
                        dispatch(CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,3));
                    }
                }).catch((err)=>{
                    console.log("Insert One Route Fail "+err);
                })
            }
            else{
                dispatch(CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
            }
        }).catch((err)=>{
            console.log("Find One Route Fail "+err);
        })
        };
}

function updateOneRoute(id,payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){


    return dispatch => {

    dataService.findone("routes",{code:payload.code,carrier:payload.carrier,company:payload.company})
    .then(response =>{

        let idstr = null;
        let ridstr = null;

        if(response.length !== 0 ){
            idstr = JSON.stringify(id);
            ridstr = JSON.stringify(response[0]._id);
        }

        if((response.length === 0) ||((idstr === ridstr))){
            dataService.updateone("routes",id,payload).then(response => {

                if(response){
                    dispatch(getRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                }
                else{
                    dispatch(CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,5));
                }
            }).catch((err)=>{
                console.log("Update One Route Fail "+err);
            })
        }
        else{
            dispatch(CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
        }
    }).catch((err)=>{
        console.log("Find One Route Fail "+err);
    })
    };
}

function deleteOneRoute(id,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.deleteone("routes",id).then(response => {

            if(response){
                dispatch(getRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
            }
            else{
                dispatch(CodeFailgetRoutes(orderBydesc,company,carrier,hasdcnpj,hasccnpj,5));
            }
        }).catch((err)=>{
            console.log("Delete One Route Fail "+err);
        })
        
    };
}

function findOneCompany(cnpj,type,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.findreallyone("companies",{cnpj:cnpj,type:type},{"projection":{"_id":0,"update":0}}).then(response =>{

           dataService.get("routes",query,options).then(routes =>{

            if((type === 1) && response){
                dispatch(FindRCNPJ(routes,orderBydesc,response,carrier,true,hasccnpj));
            }
            else if((type === 2)&& response){
                dispatch(FindRCNPJ(routes,orderBydesc,company,response,hasdcnpj,true));
            } 
            else if((type === 1)&& !response){
                dispatch(CodeFailgetRoutes(orderBydesc,null,carrier,hasdcnpj,hasccnpj,1));
            }
            else if((type === 2)&& !response){
                dispatch(CodeFailgetRoutes(orderBydesc,company,null,hasdcnpj,hasccnpj,1));
            }
            
            }).catch((err)=>{
                console.log("Get Routes Fail "+err);
            })
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}


export function changeRoutesList(routes,orderBydesc,company,carrier,hasdcnpj,hasccnpj){
    return{
        type: "FETECHED_ALL_ROUTES",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        routes: routes,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}

export function CodeRouteExist(routes,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){
    return{
        type: "RCODE_EXIST",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        routes: routes,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj,
        optype:optype
    }
}

export function FindRCNPJ(routes,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return{
        type: "FIND_RCNPJ",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        routes: routes,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}
