import { dataService } from '../_services/';

export const driverAction = {
    getDrivers,
    insertOneDriver,
    updateOneDriver,
    deleteOneDriver,
    CodeFailgetDrivers,
    findOneCompany,
};

function getDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }


    return dispatch => {
        dataService.get("drivers",query,options).then(response =>{
            
            dispatch(changeDriversList(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj));
    }).catch((err)=>{
        console.log("Get Drivers Fail "+err);
    })
    };
}

function CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.get("drivers",query,options).then(response =>{
            dispatch(CodeDriverExist(response,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype));
    }).catch((err)=>{
        console.log("Get Drivers Fail "+err);
    })
    };
}

function insertOneDriver(payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.findone("drivers",{code:payload.code,carrier:payload.carrier,company:payload.company})
        .then(response =>{
            //console.log(response.length)
    
            if(response.length === 0){
                dataService.insertone("drivers",payload).then(response => {

                    if(response){
                        dispatch(getDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                    }
                    else{
                        dispatch(CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,3));
                    }
                }).catch((err)=>{
                    console.log("Insert One Driver Fail "+err);
                })
            }
            else{
                dispatch(CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
            }
        }).catch((err)=>{
            console.log("Find One Driver Fail "+err);
        })
        };
}

function updateOneDriver(id,payload,orderBydesc,company,carrier,hasdcnpj,hasccnpj){


    return dispatch => {

    dataService.findone("drivers",{code:payload.code,carrier:payload.carrier,company:payload.company})
    .then(response =>{

        let idstr = null;
        let ridstr = null;

        if(response.length !== 0 ){
            idstr = JSON.stringify(id);
            ridstr = JSON.stringify(response[0]._id);
        }

        if((response.length === 0) ||((idstr === ridstr))){
            dataService.updateone("drivers",id,payload).then(response => {

                if(response){
                    dispatch(getDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
                }
                else{
                    dispatch(CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,4));
                }
            }).catch((err)=>{
                console.log("Update One Driver Fail "+err);
            })
        }
        else{
            dispatch(CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,2));
        }
    }).catch((err)=>{
        console.log("Find One Driver Fail "+err);
    })
    };
}

function deleteOneDriver(id,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return dispatch => {

        dataService.deleteone("drivers",id).then(response => {

            if(response){
                dispatch(getDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj));
            }
            else{
                dispatch(CodeFailgetDrivers(orderBydesc,company,carrier,hasdcnpj,hasccnpj,5));
            }
        }).catch((err)=>{
            console.log("Delete One Driver Fail "+err);
        })
        
    };
}

function findOneCompany(cnpj,type,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    let query = {};
    let options = {};

    if(orderBydesc === true){
        options = {"sort":{"code":-1}};
    }
    else{
        options = {"sort":{"code":1}};
    }

    return dispatch => {
        dataService.findreallyone("companies",{cnpj:cnpj,type:type},{"projection":{"_id":0}}).then(response =>{

           dataService.get("drivers",query,options).then(drivers =>{

            if((type === 1) && response){
                dispatch(FindDCNPJ(drivers,orderBydesc,response,carrier,true,hasccnpj));
            }
            else if((type === 2)&& response){
                dispatch(FindDCNPJ(drivers,orderBydesc,company,response,hasdcnpj,true));
            } 
            else if((type === 1)&& !response){
                dispatch(CodeFailgetDrivers(orderBydesc,null,carrier,hasdcnpj,hasccnpj,1));
            }
            else if((type === 2)&& !response){
                dispatch(CodeFailgetDrivers(orderBydesc,company,null,hasdcnpj,hasccnpj,1));
            }
            
        }).catch((err)=>{
            console.log("Get Drivers Fail "+err);
        })
    }).catch((err)=>{
        console.log("Get Companies Fail "+err);
    })
    };
}


export function changeDriversList(drivers,orderBydesc,company,carrier,hasdcnpj,hasccnpj){
    return{
        type: "FETECHED_ALL_DRIVERS",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        drivers: drivers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}

export function CodeDriverExist(drivers,orderBydesc,company,carrier,hasdcnpj,hasccnpj,optype){
    return{
        type: "DCODE_EXIST",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        drivers: drivers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj,
        optype:optype
    }
}

export function FindDCNPJ(drivers,orderBydesc,company,carrier,hasdcnpj,hasccnpj){

    return{
        type: "FIND_DCNPJ",
        company:company,
        carrier:carrier,
        orderbydesc:orderBydesc,
        drivers: drivers,
        hasdcnpj:hasdcnpj,
        hasccnpj:hasccnpj
    }
}