import { history } from '../_helpers';
import { client} from '../stitch/stitch';
import {
    FunctionCredential
  } from 'mongodb-stitch-browser-sdk';

export const userActions = {
    login,
    logout,
};

function login(email, password){
    return dispatch => {
        
        const payload = {
           email: email,
           password: password,
           isdriver: false,
           acode : ""
       } 

       const credential = new FunctionCredential(payload);
       
       client.auth.loginWithCredential(credential).then(user => {
            
            console.log("Authentication DB OK User = "+user.id.toString());

            client.callFunction("useridUpdate",[user.id,payload.email,payload.password]).then(response =>{

                //alert(JSON.stringify(response))
                if(response){
                    localStorage.setItem('auth', JSON.stringify(response));
                    dispatch(setUserDetails(response));
                    history.push('/coletas');
                }
           
        }).catch((err)=>{
            console.log(err);
        })
    })
}
}
       
function logout(){
    return dispatch => {
        localStorage.removeItem('auth');
        dispatch(logoutUser());
        history.push('/coletas');
    }
}

export function setUserDetails(user){
      return{
          type: "LOGIN_SUCCESS",
          auth: user,
      }
}

export function logoutUser(){
      return{
          type: "LOGOUT_SUCCESS",
          auth: false,
      }
}