import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Appdrawer from '../_components/appdrawer'
import { companyAction } from '../_actions';
import {RegisterTable} from '../_components/registertable'
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Business from '@material-ui/icons/Business';
import { green } from '@material-ui/core/colors';
import { red }  from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../_components/coypright';
import Tooltip from '@material-ui/core/Tooltip';
import {dateConverter} from '../libs/dateconverter';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
    root: {
       flexGrow: 1,
    },
    home: {
       paddingLeft: theme.spacing(45),
       paddingTop : theme.spacing(4),
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tablecell:{
      fontSize: '8pt',
      paddingRight: 4,
      paddingLeft: 5
    }
  }));

  const ctypes = [
    {
      value: 0,
      label:'NTI'
    },
    {
      value: 1,
      label:'Laticínio',
    },
    {
      value: 2,
      label: 'Transportador',
    }
  ];

class Company extends Component{


    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(companyAction.getCompanies(true));
    }

    errorMSG(optype){

      let alertmsg = "";

        if(optype === 1){
          alertmsg = "Erro,Empresa não Cadastrada.";
        }
        else if(optype === 2){
          alertmsg ="Erro,Já Existe uma Empresa Cadastrada com esse Código";
        }
        else if(optype === 3){
          alertmsg ="Erro,Não foi Possível Inserir Empresa";
        }
        else if(optype === 4){
          alertmsg ="Erro,Não foi Possível Modificar Empresa";
        }
        else if(optype === 5){
          alertmsg ="Erro,Não foi Possível Remover Empresa";
        }

        return alertmsg;
    }

    render(){
        const { classes,dispatch,auth} = this.props;
        const {success,companies,orderbydesc,optype} = this.props.companies;

        let alertmsg = this.errorMSG(optype);

        function CompanyForm(props) {

            const classes = useStyles();
            let label = null;
            let buttonlabel = null;
            const {row,type,handleClose} = props;
            const [cnpj,setCNPJ] = React.useState(row?row.cnpj:'');
            const [name,setName] = React.useState(row?row.name:'');
            const [showchild,setChild] = React.useState(true);
            const [errorcnpj,setErrorCNPJ] = React.useState('');
            const [ctype, setCType] = React.useState(row?row.type:1);
            const [chq, setCHQ] = React.useState(row?row.hashq:false);
            const [hqcnpj,sethqCNPJ] = React.useState(row?row.hq.cnpj:'');
            const [hqname,sethqName] = React.useState(row?row.hq.name:'');
            const [errorhqcnpj,setErrorhqCNPJ] = React.useState('');
            const [active,setActive] = React.useState(row?row.active:true);

            if(type === 1){
                label = "Cadastrar Empresa";
                buttonlabel = "Cadastrar";
              }
              else if(type === 2){
                label = "Modificar Empresa";
                buttonlabel = "Modificar";
              }
              else if(type === 3){
                label = "Remover Empresa";
                buttonlabel = "Remover";
              }
            
            const ChangeCNPJ = (event) =>{

                setCNPJ(event.target.value)
  
                if(event.target.value.match(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/)){
                  setErrorCNPJ('');
                }
                else{
                  setErrorCNPJ('Formato Inválido');
                }
              };

            const ChangehqCNPJ = (event) =>{

                sethqCNPJ(event.target.value)
  
                if(event.target.value.match(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/)){
                  setErrorhqCNPJ('');
                }
                else{
                  setErrorhqCNPJ('Formato Inválido');
                }
              };

            const ChangeCType = (event) => {
                setCType(event.target.value);
              };

            const handleHQChange = (event) => {
                setCHQ(event.target.checked);
              };

            const handleActiveChange = (event) =>{
                setActive(event.target.checked);
            };

            const button = (operation) =>{
      
                if (name && cnpj) {
    
                  let hq={name:hqname,cnpj:hqcnpj};
                  
                  let payload = {name:name,cnpj:cnpj,type:parseInt(ctype),active:active,hashq:chq,
                    hq:hq,update:{user:auth.name,time:new Date().getTime()}};
  
                  switch(operation){
                    case 1:
                      dispatch(companyAction.insertOneCompany(payload,orderbydesc));
                      break;
                    case 2:
                      setChild(false);
                      
                      dispatch(companyAction.updateOneCompany(row._id,payload,orderbydesc));
                      handleClose();
                      break;
                    case 3:
                      setChild(false);
                      dispatch(companyAction.deleteOneCompany(row._id,orderbydesc));
                      handleClose();
                      break;
                    default:
                      break;
  
                  }
                }
            };
          
            return (
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <Business />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    {label}
                  </Typography>
                  {showchild &&<form className={classes.form} noValidate>
                    <FormControlLabel
                      control={<Checkbox checked={active} onChange={handleActiveChange} color="primary" />}
                      label="Ativo"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      autoComplete="name"
                      value={name}
                      onChange ={(e) => setName(e.target.value)}
                      //autoFocus
                    />
                    <TextField
                      error={errorcnpj===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="cnpj"
                      label="CNPJ"
                      name="cnpj"
                      autoComplete="cnpj"
                      value={cnpj}
                      onChange ={ChangeCNPJ}
                      helperText = {errorcnpj}
                    />
                     <TextField
                        id="standard-select-currency-native"
                        select
                        margin="normal"
                        required
                        fullWidth
                        label=""
                        value={ctype}
                        onChange={ChangeCType}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Selecione a Empresa"
                        autoFocus
                        >
                        {ctypes.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                    </TextField>
                    <FormControlLabel
                      control={<Checkbox checked={chq} onChange={handleHQChange} color="primary" />}
                      label="Possui Matriz?"
                    />
                    {chq?<TextField
                      margin="normal"
                      required
                      fullWidth
                      id="hqname"
                      label="Nome"
                      name="hqname"
                      autoComplete="hqname"
                      value={hqname}
                      onChange ={(e) => sethqName(e.target.value)}
                    />:null}
                    {chq?<TextField
                      error={errorcnpj===''?false:true}
                      margin="normal"
                      required
                      fullWidth
                      id="hqcnpj"
                      label="CNPJ"
                      name="hqcnpj"
                      autoComplete="hqcnpj"
                      value={hqcnpj}
                      onChange ={ChangehqCNPJ}
                      helperText = {errorhqcnpj}
                    />:null}
                    <Button
                      //type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={()=>button(type)}
                      disabled = {(errorcnpj===''?false:true) || (errorhqcnpj===''?false:true)}
                    >
                      {buttonlabel}
                    </Button>
                  </form>}
                </div>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Container>
            );
          }

        function CompaniesTableHead(props)
         {
            const {handleClickOpen} = props;
            const [orderBy,setOrderBy] = React.useState(orderbydesc);
            const classes = useStyles();
            const classn = classes.tablecell;

            const createSortHandler = (event) => {
               let aux = !orderBy;
               setOrderBy(aux);
               dispatch(companyAction.getCompanies(aux));
          
            };

            return(
              <TableHead>
              <TableRow>
                <TableCell className={classn}>Nome</TableCell>
                <TableCell className={classn}>CNPJ</TableCell>
                <TableCell className={classn}>Tipo</TableCell>
                <TableCell className={classn}>Ativo</TableCell>
                <TableCell className={classn}>Matriz/CNPJ</TableCell>
                <TableCell className={classn}>Ultima Alteração
                <TableSortLabel
                        active={true}
                        direction={orderBy?'desc':'asc'}
                        onClick={createSortHandler}
                  />
                </TableCell>
                <TableCell> 
                <Tooltip title="Adicionar">
                  <IconButton onClick={() => handleClickOpen(null,1)}> 
                    <AddCircleIcon fontSize="small" style={{ color: green[500] }}/> 
                  </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            );
         }

         function CompaniesTableBody(props)
          {
            const {rows,handleClickOpen,loading,search} = props;
            const classes = useStyles();
            const classn = classes.tablecell;

            return(
              <TableBody>
              {!loading ? rows
              .filter(row =>!search || row.name.includes(search) || 
              row.cnpj.includes(search))
              .map((row) => (
              
                <TableRow key={row.update.time}>
                  <TableCell className={classn} component="th" scope="row">{row.name}</TableCell>
                  <TableCell className={classn}>{row.cnpj}</TableCell>
                  <TableCell className={classn}>{row.type===0?"NTI":row.type===1?"Laticínio":row.type===2?"Transportador":''}</TableCell>
                  <TableCell> <Checkbox checked={row.active} color="primary" /></TableCell>
                  <TableCell className={classn}>
                       <p>{row.hq?row.hq.name:''}</p>
                       <p>{row.hq?row.hq.cnpj:''}</p>
                  </TableCell>
                  <TableCell className={classn}>
                    <p>Usuário: {row.update.user}</p>
                    <p>{dateConverter.UnixtoDate(row.update.time)}</p>
                    <p>{dateConverter.UnixtoTime(row.update.time)}</p>
                  </TableCell>
                  <TableCell>
                  <Tooltip title="Editar">
                    <IconButton onClick={() => handleClickOpen(row,2)}> 
                      <EditIcon fontSize="small" style={{ color: green[500] }}/> 
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Remover">
                    <IconButton onClick={() => handleClickOpen(row,3)}> 
                      <DeleteIcon fontSize="small" style={{ color: red[300] }}/> 
                    </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                
              )): null}
              </TableBody>
            );
         }

        return(
            <div className={classes.root}>
                <Appdrawer Table={RegisterTable} Rows={companies} istable={true} 
                 THead={CompaniesTableHead} TBody={CompaniesTableBody} DialogForm={CompanyForm}
                 title={"Empresas"} success={success} emsg={alertmsg}/>
            </div>
        );
    }

}

Company.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        companies : state.company,
        auth : state.authentication.auth
     };
}
const connectedCompanyPage = withRouter(connect(mapStateToProps, null, null, {
     pure: false
})(withStyles(styles)(Company)));

export { connectedCompanyPage as Company };