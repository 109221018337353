import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { Router, Switch, Route} from 'react-router-dom';
import { Login } from './login/';
import { history } from './_helpers';
import { PrivateRoute } from './_components/PrivateRoute';
import { Home } from './home/';
import { RouteP } from './route/';
import { Driver } from './driver/';
import { Producer } from './producer/';
import { Company } from './company/';
import { User } from './user/';
import { Report } from './report/';
import {Main} from './main';
import { userActions } from './_actions';
import { connect } from 'react-redux';


const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red,
  },
})

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    //this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    //this.warnTimeout = setTimeout(this.warn, 1 * 1000);

    this.logoutTimeout = setTimeout(this.logout, 300 * 1000); //5min
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  /*warn() {
    alert("");
  }*/

  logout() {

    //alert ("Logout")
    const { dispatch } = this.props;
    dispatch(userActions.logout());
     //this.destroy(); // Cleanup
  }

 /* destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }*/

  render() {
     return (
      <MuiThemeProvider theme={theme}>
         <div className="App">
             <Router history={history}>
               <div>
                   <Switch>
                      <PrivateRoute exact path='/relatorios' component={Report} />
                      <PrivateRoute exact path='/coletas' component={Home} />
                      <PrivateRoute exact path='/rotas' component={RouteP} />
                      <PrivateRoute exact path='/motoristas' component={Driver} />
                      <PrivateRoute exact path='/produtores' component={Producer} />
                      <PrivateRoute exact path='/empresas'  component={Company} />
                      <PrivateRoute exact path='/usuarios'  component={User} />
                      <Route exact path='/' component={Main} />
                      <Route exact path='/login' component={Login} />
                   </Switch>
               </div>
             </Router>
         </div>
      </MuiThemeProvider>
      );
   }
}

const mapStateToProps = (state) =>{
  const { loggingIn } = state.authentication;
  return {
     loggingIn
  };
}

export default connect(mapStateToProps)(App);
