const initialState = {
    success:true,
    collections:null,
    isopened  : false,
}

export function report(state = initialState, action) {
    switch (action.type) {
        case 'RTABLE_IS_OPENED':
            return {
                ...state,
                success:true,
                collections:action.collections,
                isopened:action.isopened,
            };
        case 'RTABLE_IS_CLOSED':
            return {
                ...state,
                success:true,
                collections:action.collections,
                isopened:action.isopened,
            };
        default:
            return state
     }
}