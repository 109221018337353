import { db} from '../stitch/stitch';

export const dataService = {
    get,
    insertone,
    updateone,
    findone,
    findreallyone,
    deleteone,
    aggregationbyDate,
};

function get(collection,query,options){
    return(
        db.collection(collection).find(query,options).asArray().then(routes =>{
            return routes;
        })
        .catch(error => {
            console.log( collection+" Fail "+error)
            return null;
        }));
}

function insertone(collection,payload){
    return(
        db.collection(collection).insertOne(payload).then(response =>{
            //console.log(JSON.stringify(response));
            return response;
        })
        .catch(error => {
            console.log(collection+" InsertOne Fail "+error)
            return null;
        }));
}

function findone(collection,query){

    //{code:payload.code,carrier:payload.carrier,company:payload.company}
    return(
        db.collection(collection).find(query).asArray().then(response =>{
            return response;
        })
        .catch(error => {
            console.log(collection+" FindOne Fail "+error)
            return null;
        }));
}

function findreallyone(collection,query,options){
    return(
        db.collection(collection).findOne(query,options).then(response =>{
            return response;
        })
        .catch(error => {
            console.log(collection+" FindReallyOne Fail "+error)
            alert(JSON.stringify(error))
            return null;
        }));
}


function updateone(collection,id,payload){
    return(
        db.collection(collection).updateOne({_id:id},{$set:payload}).then(response =>{
            //console.log(JSON.stringify(response));
            return response;
        })
        .catch(error => {
            console.log(collection+" UpdateOne Fail "+error)
            return null;
        }));
}

function deleteone(collection,id){
    return(
        db.collection(collection).deleteOne({_id:id}).then(response =>{
            //console.log(JSON.stringify(response));
            return response;
        })
        .catch(error => {
            console.log(collection+" DeleteOne Fail "+error)
            return null;
        }));
}

function aggregationbyDate(collection,dateI,dateF,cnpj){

    let d = new Date();
    let n = d.getTimezoneOffset();
    n = n*60000;

   /* const pipeline =   [{$match: {
        $and:[{'dataI':{$gte:dateI}},{'dataF':{$lte:dateF}}],
        //$or:[{'company.cnpj':cnpj},{'carrier.cnpj':cnpj},{'company.type':0}]
      }}, {$addFields: {
         sd:{$dateToString:{format:'%d/%m/%Y',date:{ $add: [ new Date(0), {$subtract:['$dataI',n]} ] }}},
         st:{$dateToString:{format:'%H:%M:%S',date:{ $add: [ new Date(0), {$subtract:['$dataI',n]} ] }}},
   
       
      }}, {$group: {
        _id:{sd:'$sd'},
        doc:{$push:"$$ROOT"},
        vol:{$sum:'$volume'},
        se1:{$avg:'$sensors.value1'},
        se2:{$avg:'$sensors.value2'},
        se3:{$avg:'$sensors.value3'},
        se4:{$avg:'$sensors.value4'},
      
        }}]*/

        const pipeline =   [{$match: {
            $and:[{'dataI':{$gte:dateI}},{'dataF':{$lte:dateF}}],
            $or:[{'company.cnpj':cnpj},{'carrier.cnpj':cnpj},{'company.hq.cnpj':cnpj},{'carrier.hq.cnpj':cnpj}]
          }},
          {$sort:{dataI:-1}},
          {$addFields: {
             sd:{$dateToString:{format:'%d/%m/%Y',date:{ $add: [ new Date(0), {$subtract:['$dataI',n]} ] }}},
             //st:{$dateToString:{format:'%H:%M:%S',date:{ $add: [ new Date(0), {$subtract:['$dataI',n]} ] }}},
       
           
          }}, 
          {$group: {
            _id:{sd:'$sd'},
            doc:{$push:"$$ROOT"},
            vol:{$sum:'$volume'},
            st1:{$avg: {$cond: { if: { $eq: [ '$sensors.type1', 1 ] }, then: '$sensors.value1', else: false }}},
            sc1:{$avg: {$cond: { if: { $eq: [ '$sensors.type1', 2 ] }, then: '$sensors.value1', else: false }}},
            st2:{$avg: {$cond: { if: { $eq: [ '$sensors.type2', 1 ] }, then: '$sensors.value2', else: false }}},
            sc2:{$avg: {$cond: { if: { $eq: [ '$sensors.type2', 2 ] }, then: '$sensors.value2', else: false }}},
            st3:{$avg: {$cond: { if: { $eq: [ '$sensors.type3', 1 ] }, then: '$sensors.value3', else: false }}},
            sc3:{$avg: {$cond: { if: { $eq: [ '$sensors.type3', 2 ] }, then: '$sensors.value3', else: false }}},
            st4:{$avg: {$cond: { if: { $eq: [ '$sensors.type4', 1 ] }, then: '$sensors.value4', else: false }}},
            sc4:{$avg: {$cond: { if: { $eq: [ '$sensors.type4', 2 ] }, then: '$sensors.value4', else: false }}},
          
            }},
            ]

     
            

        //alert(JSON.stringify(pipeline))
    return(

        db.collection(collection).aggregate(pipeline).toArray().then(response => {
            return response;
        }) 
        .catch(error => {
            console.log(collection+" Aggregation Fail "+error)
            return null;
        }));
}



