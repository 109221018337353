import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { history } from '../_helpers';
import { userActions } from '../_actions';
import Copyright from '../_components/coypright';


const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    
    },
    button: {
      margin: theme.spacing(3, 0, 2),
    },
    img:{
      height:160,
      width:160,
    },
  });


class Login extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            email: '',
            password: '',
        }
    }

    componentDidMount() {
   
        if(localStorage.getItem('auth')){
          history.push('/coletas');
        }
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    login = event =>{
       // this.setState({ submitted: true });
        const { email, password } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.login(email, password));
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <img src={require('../ntigreen.png')} alt="NTI" className={classes.img} />
              {/*<Avatar className={classes.avatar}>
                <LockOutlinedIcon />
               </Avatar>
              <Typography component="h1" variant="h5">
                Log in
              </Typography>*/}
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={this.state.email}
                  onChange = {this.handleChange('email')}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange = {this.handleChange('password')}
                />

                <Button
                  //type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={(event)=>{this.login()}}
                >
                  Log In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Esqueceu a Senha?
                    </Link>
                  </Grid>
                  {/*<Grid item>
                    <Link href="#" variant="body2">
                      {"Não Possui uma Conta? Registre-se"}
                    </Link>
                  </Grid>*/}
                </Grid>
              </form>
            </div>
            <Box mt={8}>
             <Copyright/>
            </Box>
          </Container>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) =>{
    const { loggingIn } = state.authentication;
    return {
       loggingIn
    };
}

const connectedLoginPage = withRouter(connect(mapStateToProps, null, null, {
   pure: false
})(withStyles(useStyles)(Login)));

export { connectedLoginPage as Login };