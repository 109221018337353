import {
    Stitch,
    RemoteMongoClient,
    //AnonymousCredential,
    FunctionCredential
  } from 'mongodb-stitch-browser-sdk';

export const client = Stitch.initializeDefaultAppClient('nticollectorapp-kwnrc');
export const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('collector');
export const app = Stitch.defaultAppClient;

export class AppStitch{

    constructor(client,db){
        this.client = client;
        this.db = db;
        this.user = null;
    }

    connect() {

       const credential = new FunctionCredential({ email: "jvell@nti.com" });

       this.client.auth.loginWithCredential(credential).then(user => {
            console.log("Authentication DB OK User = "+user.id.toString());
            //return user;
    
            console.dir(user)
        
           
        }).catch((err)=>{
            console.log(err);

            //return null;
        });
    }    
}



