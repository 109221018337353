import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { history } from '../_helpers';
import Typography from '@material-ui/core/Typography';
//import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    login: {
        marginLeft: theme.spacing(2),
    },
    img:{
        height:60,
        width:60,
        //flexGrow: 1,
    },
    title:{
        flexGrow: 1,
    },
    toolbarSecondary: {
        //justifyContent: 'space-between',
        overflowX: 'auto',
      },
      toolbarLink: {
        padding: theme.spacing(1),
        //flexShrink: 0,
      },
}));

export default function Header(props) {
  const classes = useStyles();
  const {title,sections } = props;

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar >
        <img src={require('../logo.svg')} alt="NTI" className={classes.img} />
        <Typography variant="h6" className={classes.title}>
         {title}
        </Typography>
        <Button className={classes.login} color="inherit" onClick={()=>history.push('/login')}>Login</Button>
        </Toolbar>
      </AppBar>
      {/* <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
       {sections.map((section) => (
          <Link
            color="inherit"
            noWra
            key={section.title}
            variant="body2"
            href= {section.url}
            className={classes.toolbarLink}
          >
            {section.title}
         </Link>

        ))}
        </Toolbar>*/}
    </React.Fragment>
  );
}

Header.propTypes = {
  
  title: PropTypes.string,
};