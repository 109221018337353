const auth = JSON.parse(localStorage.getItem("auth"));

const initialState = auth ? { loggedIn: true, auth } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                loggingIn: true,
                auth: action.auth,
            };
      
        case 'LOGOUT_SUCCESS':
            return {
                auth: false
            };
        
        default:
            return state
    }
}