import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Appdrawer from '../_components/appdrawer'
import { routeAction } from '../_actions';
import {RegisterTable} from '../_components/registertable'
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { green } from '@material-ui/core/colors';
import { red }  from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import ExploreIcon from '@material-ui/icons/Explore';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../_components/coypright';
import Tooltip from '@material-ui/core/Tooltip';
import {dateConverter} from '../libs/dateconverter';


const styles = theme => ({
     root: {
        flexGrow: 1,
     },
     home: {
        paddingLeft: theme.spacing(45),
        paddingTop : theme.spacing(4),
     }
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  tablecell:{
    fontSize: '8pt',
    paddingRight: 4,
    paddingLeft: 5
  }
}));


class Route extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        let auth = JSON.parse(localStorage.getItem("auth"));

        if(auth.company.type === 0){
          dispatch(routeAction.getRoutes(true,null,null,false,false));
        }
        else if(auth.company.type === 1){
          dispatch(routeAction.getRoutes(true,auth.company,null,false,false));
        }
        else if(auth.company.type === 2){
          dispatch(routeAction.getRoutes(true,null,auth.company,false,false));
        }

    }

    errorMSG(optype){

      let alertmsg = "";

        if(optype === 1){
          alertmsg = "Erro,Empresa não Cadastrada.";
        }
        else if(optype === 2){
          alertmsg ="Erro,Já Existe uma Rota Cadastrada com esse Código";
        }
        else if(optype === 3){
          alertmsg ="Erro,Não foi Possível Inserir Rota";
        }
        else if(optype === 4){
          alertmsg ="Erro,Não foi Possível Modificar Rota";
        }
        else if(optype === 5){
          alertmsg ="Erro,Não foi Possível Remover Rota";
        }

        return alertmsg;
    }

    render() {
         const { classes,dispatch,auth} = this.props;
         const {success,routes,orderbydesc,company,carrier,hasdcnpj,hasccnpj,optype} = this.props.routes;

         let alertmsg = this.errorMSG(optype);
         
         function RouteForm(props)
         {
            let dsuccess = company?true:false;
            let csuccess = carrier?true:false;
            const classes = useStyles();
            const {row,type,handleClose} = props;
            let label = null;
            let buttonlabel = null;
            const [code,setCode] = React.useState(row?row.code:'');
            const [name,setName] = React.useState(row?row.name:'');
            const [showchild,setChild] = React.useState(true);
            const [dcnpj,setdCnpj] = React.useState((!hasdcnpj && row)?row.company.cnpj:company?company.cnpj:'');
            const [ccnpj,setCCnpj] = React.useState((!hasccnpj && row)?row.carrier.cnpj:carrier?carrier.cnpj:'');
            const [errordcnpj,setErrordCNPJ] = React.useState('');
            const [errorccnpj,setErrorCCNPJ] = React.useState('');
          
        
            if(type === 1){
              label = "Cadastrar Rota";
              buttonlabel = "Cadastrar";
            }
            else if(type === 2){
              label = "Modificar Rota";
              buttonlabel = "Modificar";
              dsuccess = true;
              csuccess = true;
            }
            else if(type === 3){
              label = "Remover Rota";
              buttonlabel = "Remover";
              dsuccess = true;
              csuccess = true;
            }

            const ChangeDCNPJ = (event) =>{

              setdCnpj(event.target.value)

              if(event.target.value.match(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/)){
                setErrordCNPJ('');
                dispatch(routeAction.findOneCompany(event.target.value,1,orderbydesc,company,carrier,hasdcnpj,hasccnpj));
              }
              else{
                setErrordCNPJ('Formato Inválido');
                //localStorage.removeItem('company');
              }
            };

            const ChangeCCNPJ = (event) =>{

              setCCnpj(event.target.value)

              if(event.target.value.match(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/)){
                setErrorCCNPJ('');
                dispatch(routeAction.findOneCompany(event.target.value,2,orderbydesc,company,carrier,hasdcnpj,hasccnpj));
              }
              else{
                setErrorCCNPJ('Formato Inválido');
                //localStorage.removeItem('company');
              }
            };
      
          const button = (operation) =>{
      
              if (name && code) {

                let access = [];

                access.push((!hasdcnpj && row)?row.company.cnpj:company?company.cnpj:'');

                if(((!hasdcnpj && row)?row.company.hashq:false)||company?company.hashq:false){
                  access.push((!hasdcnpj && row)?row.company.hq.cnpj:company?company.hq.cnpj:'');
                }

                access.push((!hasccnpj && row)?row.carrier.cnpj:carrier?carrier.cnpj:'');
               
                if(((!hasdcnpj && row)?row.carrier.hashq:false)||carrier?carrier.hashq:false){
                  access.push((!hasccnpj && row)?row.carrier.hq.cnpj:carrier?carrier.hq.cnpj:'');
                }
                
                let payload = {name:name,code:code,access:access,company:company,carrier:carrier,message:0,
                  update:{user:auth.name,time:new Date().getTime()}};


                switch(operation){
                  case 1:
                    dispatch(routeAction.insertOneRoute(payload,orderbydesc,company,carrier,hasdcnpj,hasccnpj));
                    break;
                  case 2:
                    setChild(false);

                    if(!hasdcnpj){
                      payload.company = row.company;
                    }
                    if(!hasccnpj){
                      payload.carrier = row.carrier;
                    }
                    
                    dispatch(routeAction.updateOneRoute(row._id,payload,orderbydesc,company,carrier,hasdcnpj,hasccnpj));
                    handleClose();
                    break;
                  case 3:
                    setChild(false);
                    dispatch(routeAction.deleteOneRoute(row._id,orderbydesc,company,carrier,hasdcnpj,hasccnpj));
                    handleClose();
                    break;
                  default:
                    break;

                }
              }
          };
        
          return (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <ExploreIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {label}
                </Typography>
                {showchild &&<form className={classes.form} noValidate>
                
                  <TextField
                    error={dsuccess?errordcnpj===''?false:true:true}
                    margin="normal"
                    required
                    fullWidth
                    id="dairy"
                    label="CNPJ/Laticínio"
                    name="dairy"
                    autoComplete="dairy"
                    helperText = {dsuccess?errordcnpj:"CNPJ Não Cadastrado"}
                    value={dcnpj}
                    onChange ={ChangeDCNPJ}
                    //autoFocus = {auth.company.type !== 1 && auth.company.type === 0?true:false}
                    disabled = {auth.company.type === 1?true:false}
                />
              
                  <TextField
                    error={csuccess?errorccnpj===''?false:true:true}
                    margin="normal"
                    required
                    fullWidth
                    id="carrier"
                    label="CNPJ/Transportador"
                    name="carrier"
                    autoComplete="carrier"
                    helperText = {csuccess?errorccnpj:"CNPJ Não Cadastrado"}
                    value={ccnpj}
                    onChange ={ChangeCCNPJ}
                    //autoFocus = {auth.company.type !== 2 && auth.company.type !== 0?true:false}
                    disabled = {auth.company.type === 2?true:false}
                />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Código"
                    name="code"
                    value={code}
                    autoComplete="code"
                    //autoFocus = {auth.company.type ===2?false:true}
                    onChange ={(e) => setCode(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nome"
                    name="name"
                    value={name}
                    autoComplete="name"
                    onChange ={(e) => setName(e.target.value)}
                  />
                  <Button
                    //type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={()=>button(type)}
                    disabled = {(errordcnpj===''?false:true) || (errorccnpj===''?false:true) || 
                                dcnpj===''?true:false || ccnpj===''?true:false}
                  >
                    {buttonlabel}
                  </Button>
                </form>}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
          );

         }

         function RoutesTableHead(props)
         {
            const {handleClickOpen} = props;
            const [orderBy,setOrderBy] = React.useState(orderbydesc);
            const classes = useStyles();
            const classn = classes.tablecell;

            const createSortHandler = (event) => {
               let aux = !orderBy;
               setOrderBy(aux);
               dispatch(routeAction.getRoutes(aux,company,carrier,hasdcnpj,hasccnpj));
          
            };

            return(
              <TableHead>
              <TableRow>
                <TableCell className={classn}>Código
                  <TableSortLabel
                        active={true}
                        direction={orderBy?'desc':'asc'}
                        onClick={createSortHandler}
                  />
                </TableCell>
                <TableCell className={classn}>Nome</TableCell>
                <TableCell className={classn}>Transportador/CNPJ</TableCell>
                <TableCell className={classn}>Laticínio/CNPJ</TableCell>
                <TableCell className={classn}>Ultima Alteração</TableCell>
                <TableCell> 
                <Tooltip title="Adicionar">
                  <IconButton onClick={() => handleClickOpen(null,1)}> 
                    <AddCircleIcon fontSize="small" style={{ color: green[500] }}/> 
                  </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            );
         }
        
         function RoutesTableBody(props)
          {
            const {rows,handleClickOpen,loading,search} = props;
            const classes = useStyles();
            const classn = classes.tablecell;

            return(
              <TableBody>
              {!loading ? rows
              .filter(row =>!search || row.name.includes(search) || 
              row.code.toString().includes(search) || row.company.cnpj.includes(search) || 
              row.company.name.includes(search))
              .map((row) => (
              
                <TableRow key={row.update.time}>
                  <TableCell className={classn} component="th" scope="row">
                    {row.code}
                  </TableCell>
                  <TableCell className={classn}>{row.name}</TableCell>
                  <TableCell className={classn}>
                    <p>{row.carrier.name}</p>
                    <p>{row.carrier.cnpj}</p>
                  </TableCell>
                  <TableCell className={classn}>
                    <p>{row.company.name}</p>
                    <p>{row.company.cnpj}</p>
                  </TableCell>
                  <TableCell className={classn}>
                    <p>Usuário: {row.update.user}</p>
                    <p>{dateConverter.UnixtoDate(row.update.time)}</p>
                    <p>{dateConverter.UnixtoTime(row.update.time)}</p>
                  </TableCell>
                  <TableCell>
                  <Tooltip title="Editar">
                    <IconButton onClick={() => handleClickOpen(row,2)}> 
                      <EditIcon fontSize="small" style={{ color: green[500] }}/> 
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Remover">
                    <IconButton onClick={() => handleClickOpen(row,3)}> 
                      <DeleteIcon fontSize="small" style={{ color: red[300] }}/> 
                    </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                
              )): null}
              </TableBody>
            );
         }

         return (
             <div className={classes.root}>
                 <Appdrawer Table={RegisterTable} Rows={routes} istable={true} 
                 THead={RoutesTableHead} TBody={RoutesTableBody} DialogForm={RouteForm}
                 title={"Rotas"} success={success} emsg={alertmsg}/>
             </div>

         );
         
    }
}
Route.propTypes = {
    classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        success : state.success,
        routes : state.route,
        auth : state.authentication.auth
     };
}
const connectedRoutePage = withRouter(connect(mapStateToProps, null, null, {
     pure: false
})(withStyles(styles)(Route)));

export { connectedRoutePage as RouteP };