import { combineReducers } from 'redux';
import { authentication } from './auth.reducer';
import { route } from './route.reducer';
import { driver } from './driver.reducer';
import { company } from './company.reducer';
import { user } from './user.reducer';
import { producer } from './producer.reducer';
import { collection } from './collection.reducer';
import { report } from './report.reducer';

const rootReducer = combineReducers({
    authentication,
    route,
    driver,
    company,
    user,
    producer,
    collection,
    report
});

export default rootReducer;