export const dateConverter = {
    UnixtoTime,
    UnixtoDate,
    DatetoUnix,
}

function UnixtoTime(timestamp){

    let date = new Date(timestamp);
    let hours = "0" + date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();

    let time = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return time;
}

function UnixtoDate(timestamp){

    let date = new Date(timestamp);
    let day = "0"+ date.getDate();
    let month =  date.getMonth() + 1;
    month = "0"+month;
    let year = date.getFullYear();

    let cdate = day.substr(-2) + '/' + month.substr(-2) + '/' + year;

    return cdate;
}

function DatetoUnix(selecteddate){
    
    let dateobj = new Date(selecteddate).getTime(); 

    return dateobj;
    
}